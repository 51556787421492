import React, { useEffect, useReducer } from "react";
import { Formik, Form, validateYupSchema, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { FormControl, FormActions } from "../../Form/FormControls";
import Button from "../../Button";
import Spinner from "../../Loaders/Spinner";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { useApi } from "../../../api/useApi";
import {
  updateConnectionEnabledFlag,
  updateConnectionDetails,
} from "../../../api/connectionMutations";
import useConnectionCreated from "../../../Hooks/useConnectionCreated";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import SplashLoader from "../../Loaders/SplashLoader";
import * as paths from "../../../common/paths";
import SqlConnection from "./SqlConnection";
import CosmosConnection from "./CosmosConnection";
import DataverseConnection from "./DataverseConnection";
import SftpConnection from "./SftpConnection";
import FtpsConnection from "./FtpsConnection";
import BlobConnection from "./BlobConnection";
import Modal from "../../Modal";
import OCROnlySettingsForm from "./OCROnlySettingsForm";

const ConnectionLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const ConnectionTypeSection = ({ form, connectionData }) => {
  switch (connectionData?.serverType) {
    case "SQL_SERVER":
    case "MY_SQL":
    case "ORACLE":
    case "POSTGRE_SQL":
      return <SqlConnection isEditing={true} form={form} />;
    case "AZURE_BLOB_STORAGE":
      return <BlobConnection isEditing={true} form={form} />;
    case "SFTP":
      return <SftpConnection isEditing={true} form={form} />;
    case "FTPS":
      return <FtpsConnection isEditing={true} form={form} />;
    case "COSMOS_DB":
      return <CosmosConnection isEditing={true} form={form} />;
    case "DATAVERSE":
      return <DataverseConnection isEditing={true} form={form} />;
    default:
      return null;
  }
};

const getInitialDetails = ({ connectionData }) => {
  switch (connectionData?.serverType) {
    case "SQL_SERVER":
    case "MY_SQL":
    case "ORACLE":
    case "POSTGRE_SQL":
      return {
        databaseConnectionString: {
          connectionString: null,
          defaultDatabase: null,
          password: null,
          host: null,
          port: null,
          username: null,
        },
      };
    case "AZURE_BLOB_STORAGE":
      return {
        azureStorageConnectionString: {
          connectionString: null,
          accountName: null,
          accountKey: null,
          blobUri: null,
        },
      };
    case "SFTP":
      return {
        sftpConnectionString: {
          serverAddress: null,
          username: null,
          password: null,
          privateKey: null,
        },
      };
    case "FTPS":
      return {
        ftpsConnectionString: {
          serverAddress: null,
          username: null,
          password: null,
        },
      };
    case "COSMOS_DB":
      return {
        cosmosConnectionString: {
          connectionString: null,
          cosmosUri: null,
          accountKey: null,
          database: null,
        },
      };
    case "DATAVERSE":
      return {
        dataverseConnectionString: {
          connectionString: null,
          environmentUri: null,
        },
      };
    default:
      return null;
  }
};

const ConnectionsSettingsForm = ({
  connectionId,
  connection: connectionData,
  getUpdatedConnection,
}) => {
  let history = useHistory();
  const initialState = {
    showConfirm: false,
    createError: "",
    creatingConnection: false,
  };
  const { lastConnectionCreated, setLastConnectionCreated } =
    useConnectionCreated();

  const [
    { loading: deleteLoading, errors: deleteErrors, data: deleteData },
    deleteConnection,
  ] = useApi();

  const [{ errors: updateConnectionErrors }, updateConnection] = useApi();

  //Reducer States for this component, Details what part of the state updates pending dispatch
  const reducer = (state, action) => {
    switch (action.type) {
      //First State called when a query is passed to the component.
      default:
        return {
          ...state,
        };

      case "FAILED_TEST": {
        return {
          ...state,
          creatingConnection: false,
        };
      }

      case "CREATE_ERROR": {
        setLastConnectionCreated(null);
        return {
          ...state,
          createError: action.payload,
          creatingConnection: false,
        };
      }

      case "DELETE": {
        const variables = { id: Number(connectionId), enabledState: false };
        deleteConnection({ query: updateConnectionEnabledFlag, variables });
        return {
          ...state,
        };
      }

      case "UPDATE_DETAILS": {
        const { advanced, ocrEnabled, ...cleanValues } = action.payload.values;

        const formValues = {
          id: Number(connectionId),
          ...cleanValues,
        };

        const variables = { ...formValues };
        updateConnection({ query: updateConnectionDetails, variables });
        return {
          ...state,
          creatingConnection: true,
          createError: "",
        };
      }

      case "HIDE_CONFIRM": {
        return {
          ...state,
          showConfirm: false,
        };
      }
      case "SHOW_CONFIRM": {
        return {
          ...state,
          showConfirm: true,
        };
      }
    }
  };

  //initialize the reducer state
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (updateConnectionErrors) dispatch({ type: "FAILED_TEST" });
  }, [updateConnectionErrors, dispatch]);

  useEffect(() => {
    if (lastConnectionCreated) {
      const { SourceId, ErrorMessage } = lastConnectionCreated.payload;

      if (SourceId) {
        history.push(paths.dataConnectionsSources(SourceId));
        setLastConnectionCreated(null);
      } else {
        dispatch({
          type: "CREATE_ERROR",
          payload: ErrorMessage ?? "",
        });
      }
    }
  }, [lastConnectionCreated, history, dispatch, setLastConnectionCreated]);

  useEffect(() => {
    if (deleteData) {
      history.push(paths.dataConnections());
    }
  }, [deleteData, history]);

  if (deleteLoading) return <SplashLoader text={"Deleting Connection"} />;

  const initialValues = {
    details: getInitialDetails({ connectionData }),
  };

  const ocrCapable = ["AZURE_BLOB_STORAGE", "FTPS"].includes(
    connectionData?.serverType
  );

  return (
    <>
      {state.showConfirm ? (
        <Modal
          title={`Confirm Connection Removal`}
          hide={() => {
            dispatch({ type: "HIDE_CONFIRM" });
          }}
        >
          <p>Are you sure you wish to remove this Connection?</p>
          <div>
            <Button
              type="button"
              list="true"
              danger
              onClick={() => {
                dispatch({ type: "DELETE" });
              }}
            >
              Yes
            </Button>
            <Button
              type="button"
              onClick={() => {
                dispatch({ type: "HIDE_CONFIRM" });
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      {state.creatingConnection && (
        <ConnectionLoading>
          <SplashLoader text={"Testing Connection"} />
        </ConnectionLoading>
      )}

      <Formik
        enableReinitialize={true}
        initialValues={{
          advanced: false,
          ocrEnabled: connectionData?.ocrConnection ?? false,
          ...initialValues,
        }}
        validateOnMount={true}
        validate={(values) => {
          let schema;
          if (
            connectionData.serverType === "SQL_SERVER" ||
            connectionData.serverType === "MY_SQL" ||
            connectionData.serverType === "ORACLE" ||
            connectionData.serverType === "POSTGRE_SQL"
          ) {
            schema = Yup.object().shape({
              advanced: Yup.boolean(),
              details: Yup.object().when("advanced", {
                is: true,
                then: Yup.object({
                  databaseConnectionString: Yup.object({
                    connectionString: Yup.mixed().required("Required"),
                  }),
                }),
                otherwise: Yup.object({
                  databaseConnectionString: Yup.object({
                    defaultDatabase: Yup.mixed().required("Required"),
                    password: Yup.mixed().required("Required"),
                    host: Yup.mixed().required("Required"),
                    port: Yup.mixed().required("Required"),
                    username: Yup.mixed().required("Required"),
                  }),
                }),
              }),
            });
          } else if (connectionData.serverType === "AZURE_BLOB_STORAGE") {
            schema = Yup.object().shape({
              advanced: Yup.boolean(),
              details: Yup.object().when("advanced", {
                is: true,
                then: Yup.object({
                  azureStorageConnectionString: Yup.object({
                    connectionString: Yup.mixed().required("Required"),
                  }),
                }),
                otherwise: Yup.object({
                  azureStorageConnectionString: Yup.object({
                    accountName: Yup.mixed().required("Required"),
                    accountKey: Yup.mixed().required("Required"),
                    blobUri: Yup.mixed().required("Required"),
                  }),
                }),
              }),
            });
          } else if (connectionData.serverType === "COSMOS_DB") {
            schema = Yup.object().shape({
              advanced: Yup.boolean(),
              details: Yup.object().when("advanced", {
                is: true,
                then: Yup.object({
                  cosmosConnectionString: Yup.object({
                    connectionString: Yup.mixed().required("Required"),
                  }),
                }),
                otherwise: Yup.object({
                  cosmosConnectionString: Yup.object({
                    cosmosUri: Yup.mixed().required("Required"),
                    accountKey: Yup.mixed().required("Required"),
                    database: Yup.mixed().required("Required"),
                  }),
                }),
              }),
            });
          } else if (connectionData.serverType === "SFTP") {
            schema = Yup.object().shape({
              details: Yup.object({
                sftpConnectionString: Yup.object({
                  serverAddress: Yup.mixed().required("Required"),
                  username: Yup.mixed().required("Required"),
                  password: Yup.mixed().required("Required"),
                  privateKey: Yup.mixed().nullable(),
                }),
              }),
            });
          } else if (connectionData.serverType === "DATAVERSE") {
            schema = Yup.object().shape({
              advanced: Yup.boolean(),
              details: Yup.object().when("advanced", {
                is: true,
                then: Yup.object({
                  dataverseConnectionString: Yup.object({
                    connectionString: Yup.mixed().required("Required"),
                  }),
                }),
                otherwise: Yup.object({
                  dataverseConnectionString: Yup.object({
                    environmentUri: Yup.mixed().required("Required"),
                  }),
                }),
              }),
            });
          } else if (connectionData.serverType === "FTPS") {
            schema = Yup.object().shape({
              details: Yup.object({
                ftpsConnectionString: Yup.object({
                  serverAddress: Yup.mixed().required("Required"),
                  username: Yup.mixed().required("Required"),
                  password: Yup.mixed().required("Required"),
                }),
              }),
            });
          }

          const promise = validateYupSchema(values, schema);

          return new Promise((resolve, reject) => {
            promise.then(
              () => {
                resolve({});
              },
              (err) => {
                if (err.name === "ValidationError") {
                  resolve(yupToFormErrors(err));
                } else {
                  reject(err);
                }
              }
            );
          });
        }}
      >
        {(form) => {
          return (
            <Form>
              <ConnectionTypeSection
                form={form}
                connectionData={connectionData}
              />

              {connectionId && (
                <Button
                  list="true"
                  type="button"
                  onClick={() =>
                    dispatch({
                      type: "UPDATE_DETAILS",
                      payload: {
                        values: form.values,
                      },
                    })
                  }
                >
                  Update Details
                </Button>
              )}

              <FormActions>
                <FormControl>
                  {deleteErrors ? (
                    <ErrorMessages errors={deleteErrors} />
                  ) : null}

                  {updateConnectionErrors ? (
                    <ErrorMessages errors={updateConnectionErrors} />
                  ) : null}

                  {state.createError ? (
                    <ErrorMessages errors={[{ message: state.createError }]} />
                  ) : null}

                  {connectionId && (
                    <Button
                      list="true"
                      danger
                      type="button"
                      onClick={() =>
                        dispatch({
                          type: "SHOW_CONFIRM",
                        })
                      }
                    >
                      Remove
                    </Button>
                  )}

                  {!connectionId ? (
                    <Button
                      type="button"
                      disabled={form.isSubmitting || !form.isValid}
                      onClick={() =>
                        dispatch({
                          type: "SUBMIT",
                          payload: {
                            values: form.values,
                          },
                        })
                      }
                    >
                      {form.isSubmitting ? <Spinner /> : "Submit"}
                    </Button>
                  ) : null}
                </FormControl>
              </FormActions>
            </Form>
          );
        }}
      </Formik>
      {ocrCapable && (
        <OCROnlySettingsForm
          connectionId={connectionId}
          connection={connectionData}
          getUpdatedConnection={getUpdatedConnection}
        />
      )}
    </>
  );
};

export default ConnectionsSettingsForm;
