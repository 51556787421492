import React, { useState, useEffect, useCallback, useContext } from "react";
import SortTable from "../../components/Table/SortTable";
import { format } from "date-fns";
import { dataSource } from "../../common/paths";
import StyledLink from "../../components/StyledLink";
import styled from "styled-components/macro";
import { MdWarning } from "react-icons/md";
import Modal from "../../components/Modal";
import ViewMappingModal from "./ViewMappingModal";
import ReactTooltip from "react-tooltip";
import TableButton from "../../components/Button/TableButton";
import { useApi } from "../../api/useApi";
import { setRuleInstanceEnabledFlag } from "../../api/ruleMutations";
import Button from "../../components/Button";
import Spinner from "../../components/Loaders/Spinner";
import SpinningLoader from "../../components/Loaders/SpinningLoader";
import { dataSourceFailureInstanceDetails } from "../../common/paths";
import FailureDetailsModal from "../../components/TableRowView/FailureDetailsModal";
import { NavContext } from "../../components/Layout/NavStateProvider";
import FailureViewDetails from "../../views/DataSourcesPage/Reports/FailureViewDetailsModalDrillIn";
import {
  failureOpportunities,
  totalFailures,
  rowCount,
  constraintFailuresCount,
  qualityScore,
  scoreHistory,
  ruleFailures,
  failuresHistory,
} from "../../api/dataSourceQueries";
import ErrorMessages from "../../components/Notifications/ErrorMessages";

const FailureDetailsModalContainer = ({ showFailureDetails }) => {
  const query = `
  query($id: Int!, $reportId: UUID!) {
     dataSource(id: $id) {
       id
       name
       etlPipeline{
        id
      }
      columns{
        id
        name
        ordinal
      }
      priorityLevel
      qualityImpact
      description
      reportStatus
      ruleInstancesCount
      primaryKeyConfigured
      latestReport{
        refreshSummaryId
      }
       reportById(reportId: $reportId){
         refreshSummaryId
         timestamp
         refreshSummary {
          createdOn
          batchName
        }
        columnProfiles{
          profileResults {
            columnId
            value
            valueSubType
            valueType
          }
        }
        failureDetails{
          dataSourceMetrics {
            totalFailuresCount
            totalSuccessfulRowsCount
            totalHighPriorityFailuresCount
            totalOtherPriorityFailuresCount
          }
          columnEntries {
            columnId
            details {
              failureCount
              percentFailureCount
              ruleStandardInstancedName
              standardColumnName
            }
            failureCount
            failureCountChange
            name
            previouslyTopOrder
          }
          ruleStandardEntries {
            details {
              columnName
              failureCount
              percentFailureCount
              standardColumnName
            }
            failureCount
            failureCountChange
            name
            previouslyTopOrder
            rulesStandardId
          }
        }
         ${failureOpportunities.fragment}
         ${totalFailures.fragment}
         ${rowCount.fragment}
         ${qualityScore.fragment}
         ${constraintFailuresCount.fragment}
         ${scoreHistory.fragment}
         ${ruleFailures.fragment}
         ${failuresHistory.fragment}
       }
     }
 }
`;
  const [{ loading, errors, data: apiData }, fetch] = useApi();

  const sourceId = showFailureDetails?.dataSourceId;
  const refreshSummaryId = showFailureDetails?.refreshSummaryId;
  const failedRuleInstanceId = showFailureDetails?.failedRuleInstanceId;
  const failedRuleInstanceVersionId =
    showFailureDetails?.failedRuleInstanceVersionId
      ? Number(showFailureDetails?.failedRuleInstanceVersionId)
      : null;

  const failedRuleStandardVersionId =
    showFailureDetails?.failedRuleStandardVersionId;

  useEffect(() => {
    if (sourceId && refreshSummaryId) {
      const variables = {
        id: sourceId,
        reportId: refreshSummaryId,
      };

      fetch({ query: query, variables });
    }
  }, [sourceId, refreshSummaryId, fetch, query]);
  if (loading)
    return (
      <div>
        <SpinningLoader text="Loading Failure Details" />
      </div>
    );
  return (
    <>
      {errors && <ErrorMessages errors={errors} />}
      <FailureViewDetails
        sourceId={sourceId}
        refreshSummaryId={refreshSummaryId}
        failedRuleInstanceId={failedRuleInstanceId}
        failedRuleInstanceVersionId={failedRuleInstanceVersionId}
        failedRuleStandardVersionId={failedRuleStandardVersionId}
        apiData={apiData}
      />
    </>
  );
};

const WarningIcon = styled.div`
  color: red;
  position: absolute;
  left: -1.5rem;
`;

const RemoveInstance = ({
  removeInstanceFromState,
  ruleToDelete,
  setRuleToDelete,
  setShowConfirmRemove,
}) => {
  const [
    { loading: removeRuleDataLoading, data: removeRuleData },
    removeRuleApi,
  ] = useApi();

  //handle remove rule update
  useEffect(() => {
    if (removeRuleData && ruleToDelete) {
      removeInstanceFromState(ruleToDelete?.ruleInstanceId);
      setRuleToDelete(null);
      setShowConfirmRemove(false);
    }
  }, [
    removeRuleData,
    setShowConfirmRemove,
    ruleToDelete,
    setRuleToDelete,
    removeInstanceFromState,
  ]);

  //callback for removing a row
  const removeRuleStandard = useCallback(
    (ruleInstanceId) => {
      const variables = {
        ruleInstanceId: ruleInstanceId,
        enableState: "DISABLED",
      };
      removeRuleApi({ query: setRuleInstanceEnabledFlag, variables });
    },
    [removeRuleApi]
  );

  return (
    <>
      <p>Are you sure you wish to remove this Mapping?</p>
      <div>
        <Button
          type="button"
          list="true"
          disabled={removeRuleDataLoading}
          danger
          onClick={() => removeRuleStandard(ruleToDelete?.ruleInstanceId)}
        >
          {removeRuleDataLoading ? <Spinner /> : "Yes"}
        </Button>
        <Button
          type="button"
          disabled={removeRuleDataLoading}
          onClick={() => {
            setRuleToDelete(null);
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

const Sources = ({ sourceInstances: instances, ruleId, isFilter }) => {
  const [sourceInstances, setSourceInstances] = useState(instances ?? []);
  const [showConfirm, setShowConfirm] = useState(null);
  const [showConfirmRemove, setShowConfirmRemove] = useState(null);
  const [ruleToDelete, setRuleToDelete] = useState(null);
  const [showFailureDetails, setShowFailureDetails] = useState(null);

  const removeInstanceFromState = (ruleInstanceId) => {
    setSourceInstances((prev) => {
      const clone = [...prev].filter((c) => c?.id !== ruleInstanceId);
      return [...clone];
    });
  };
  const { closeMenu } = useContext(NavContext);
  useEffect(() => {
    if (showFailureDetails) {
      closeMenu();
    }
  }, [showFailureDetails, closeMenu]);

  useEffect(() => {
    if (ruleToDelete) {
      setShowConfirmRemove(true);
    } else {
      setShowConfirmRemove(false);
    }
  }, [ruleToDelete]);

  const columnsData = isFilter
    ? [
        {
          Header: "Source Name",
          id: "sourceName",
          accessor: (d) => d?.dataSource?.name,
          Cell: ({ row: { original } }) => {
            const dataSourceId = original?.dataSource?.id;
            return (
              <StyledLink
                data-testid="link-datasource"
                to={dataSource(dataSourceId)}
              >
                {original?.dataSource?.name}
              </StyledLink>
            );
          },
        },
        {
          Header: "Filter Instance Name",
          id: "ruleInstanceName",
          accessor: (d) => d?.title,
          Cell: ({ row: { original } }) => {
            const isLatest =
              original?.latestVersion?.standardVersionId ===
              original?.latestVersion?.standardVersion?.standard?.latestVersion
                ?.id;

            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {!isLatest && (
                  <>
                    <WarningIcon data-tip="OutOfDate" data-for="OutOfDate">
                      <MdWarning />
                    </WarningIcon>
                    <ReactTooltip id="OutOfDate" type="error">
                      <span>Filter Policy Out of Date</span>
                    </ReactTooltip>
                  </>
                )}

                {original?.title}
              </div>
            );
          },
        },
        {
          Header: "Score",
          id: "score",
          accessor: (d) =>
            d?.dataSource?.latestReport?.qualityScore?.score ?? 0,
        },
        {
          Header: "Date",
          id: "data",
          accessor: (d) => d?.dataSource?.latestReport?.timestamp,
          Cell: ({ row: { original } }) => {
            const date = original?.dataSource?.latestReport?.timestamp;
            if (date) {
              return format(new Date(date), "MM-dd-yyyy HH:mm:ss");
            } else {
              return "";
            }
          },
        },
        {
          Header: "",
          id: "manageMapping",
          sortable: false,
          Cell: ({ row: { original } }) => {
            const dataSourceId = original?.dataSource?.id;
            const ruleVersionId = original?.latestVersion?.id;
            const ruleInstanceId = original?.id;

            return (
              <>
                <TableButton
                  list
                  onClick={() =>
                    setShowConfirm({
                      sourceId: dataSourceId,
                      instanceVersionId: ruleVersionId,
                      ruleInstanceId: ruleInstanceId,
                      title: original?.title,
                    })
                  }
                >
                  View Mapping
                </TableButton>
                <TableButton
                  danger
                  onClick={() =>
                    setRuleToDelete({
                      sourceId: dataSourceId,
                      instanceVersionId: ruleVersionId,
                      ruleInstanceId: ruleInstanceId,
                      title: original?.title,
                    })
                  }
                >
                  Remove
                </TableButton>
              </>
            );
          },
        },
      ]
    : [
        {
          Header: "Source Name",
          id: "sourceName",
          accessor: (d) => d?.dataSource?.name,
          Cell: ({ row: { original } }) => {
            const sourceId = original?.dataSource?.id;
            const refreshSummaryId =
              original?.dataSource?.latestReport?.refreshSummaryId;

            const failedRuleInstanceId = original?.id;
            const failedRuleInstanceVersionId = original?.latestVersion?.id;
            const failedRuleStandardVersionId =
              original?.latestVersion?.standardVersionId;

            const published = original?.enabledState === "PUBLISHED";

            return published && refreshSummaryId ? (
              <StyledLink
                data-testid="link-datasource"
                to={dataSourceFailureInstanceDetails(
                  sourceId,
                  refreshSummaryId,
                  failedRuleInstanceId,
                  failedRuleInstanceVersionId,
                  failedRuleStandardVersionId
                )}
              >
                {original?.dataSource?.name}
              </StyledLink>
            ) : sourceId &&
              failedRuleInstanceId &&
              failedRuleInstanceVersionId ? (
              <StyledLink
                data-testid="link-datasource"
                to={`/sources/${sourceId}/manage/policies/${failedRuleInstanceId}/${failedRuleInstanceVersionId}/update`}
              >
                {original?.dataSource?.name}
              </StyledLink>
            ) : (
              <div> {original?.dataSource?.name}</div>
            );
          },
        },
        {
          Header: "Policy Instance Name",
          id: "ruleInstanceName",
          accessor: (d) => d?.title,
          Cell: ({ row: { original } }) => {
            const isLatest =
              original?.latestVersion?.standardVersionId ===
              original?.latestVersion?.standardVersion?.standard?.latestVersion
                ?.id;

            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {!isLatest && (
                  <>
                    <WarningIcon data-tip="OutOfDate" data-for="OutOfDate">
                      <MdWarning />
                    </WarningIcon>
                    <ReactTooltip id="OutOfDate" type="error">
                      <span>Policy Out of Date</span>
                    </ReactTooltip>
                  </>
                )}

                {original?.title}
              </div>
            );
          },
        },
        {
          Header: "Mapping Status",
          id: "enabledState",
          accessor: (d) => d?.enabledState,
        },
        {
          Header: "Score",
          id: "score",
          accessor: (d) =>
            d?.dataSource?.latestReport?.qualityScore?.score ?? 0,
        },
        {
          Header: "Failed",
          id: "failed",
          accessor: (d) =>
            d?.dataSource?.latestReport?.ruleFailures?.failures.find(
              (failure) => d?.id === failure.failedRuleInstanceId
            )?.failureCount,
        },
        {
          Header: "Passed",
          id: "passed",
          accessor: (d) =>
            d?.dataSource?.latestReport?.ruleFailures?.failures.find(
              (failure) => d?.id === failure.failedRuleInstanceId
            )?.successCount,
        },
        {
          Header: "Date",
          id: "data",
          accessor: (d) => d?.dataSource?.latestReport?.timestamp,
          Cell: ({ row: { original } }) => {
            const date = original?.dataSource?.latestReport?.timestamp;
            if (date) {
              return format(new Date(date), "MM-dd-yyyy HH:mm:ss");
            } else {
              return "";
            }
          },
        },
        {
          Header: "",
          id: "actions",
          sortable: false,
          Cell: ({ row: { original } }) => {
            const dataSourceId = original?.dataSource?.id;
            const ruleVersionId = original?.latestVersion?.id;
            const ruleInstanceId = original?.id;
            const refreshSummaryId =
              original?.dataSource?.latestReport?.refreshSummaryId;

            const failedRuleInstanceId = original?.id;
            const failedRuleInstanceVersionId = original?.latestVersion?.id;
            const failedRuleStandardVersionId =
              original?.latestVersion?.standardVersionId;
            const failedRuleStandardId =
              original?.latestVersion?.standardVersion?.standard?.id;
            const published = original?.enabledState === "PUBLISHED";

            return (
              <>
                {published && refreshSummaryId && (
                  <TableButton
                    list
                    onClick={() =>
                      setShowFailureDetails({
                        dataSourceId,
                        refreshSummaryId,
                        failedRuleInstanceId,
                        failedRuleInstanceVersionId,
                        failedRuleStandardVersionId,
                        failedRuleStandardId,
                      })
                    }
                  >
                    Failure Details
                  </TableButton>
                )}

                <TableButton
                  list
                  onClick={() =>
                    setShowConfirm({
                      sourceId: dataSourceId,
                      instanceVersionId: ruleVersionId,
                      ruleInstanceId: ruleInstanceId,
                      title: original?.title,
                    })
                  }
                >
                  View Mapping
                </TableButton>
                <TableButton
                  danger
                  onClick={() =>
                    setRuleToDelete({
                      sourceId: dataSourceId,
                      instanceVersionId: ruleVersionId,
                      ruleInstanceId: ruleInstanceId,
                      title: original?.title,
                    })
                  }
                >
                  Remove
                </TableButton>
              </>
            );
          },
        },
      ];

  return (
    <>
      {showFailureDetails ? (
        <FailureDetailsModal
          title={"Failure Details"}
          toggle={() => setShowFailureDetails(null)}
        >
          <FailureDetailsModalContainer
            showFailureDetails={showFailureDetails}
          />
        </FailureDetailsModal>
      ) : null}

      {showConfirmRemove ? (
        <Modal
          title={`Confirm Rule Removal`}
          hide={() => setRuleToDelete(null)}
        >
          <RemoveInstance
            ruleToDelete={ruleToDelete}
            removeInstanceFromState={removeInstanceFromState}
            setRuleToDelete={setRuleToDelete}
            setShowConfirmRemove={setShowConfirmRemove}
          />
        </Modal>
      ) : null}

      {showConfirm ? (
        <Modal title={showConfirm?.title} hide={() => setShowConfirm(null)}>
          <ViewMappingModal
            sourceId={showConfirm?.sourceId}
            instanceVersionId={showConfirm?.instanceVersionId}
            ruleInstanceId={showConfirm?.ruleInstanceId}
            ruleId={ruleId}
            editEnabled={true}
            isFilter={isFilter}
          />
        </Modal>
      ) : null}
      <SortTable
        getRowProps={(row) => {
          const isLatest =
            row?.original?.latestVersion?.standardVersionId ===
            row?.original?.latestVersion?.standardVersion?.standard
              ?.latestVersion?.id;
          return {
            style: {
              background: isLatest ? "#fff" : "#FFCECE",
            },
          };
        }}
        data={sourceInstances}
        columns={columnsData}
      />
    </>
  );
};

export default Sources;
