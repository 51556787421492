export const allCustomReports = /* GraphQL */ `
  query ($first: Int, $after: String, $where: CustomReportFilterInput) {
    allCustomReports(first: $first, after: $after, where: $where) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          enabled
          exportReport
          dataSourceId
          type
        }
      }
    }
  }
`;

export const allRecordLevelReportConfigurationSummaries = /* GraphQL */ `
  query ($enabledOnly: Boolean! = true, $first: Int) {
    allRecordLevelReportConfigurationSummaries(
      enabledOnly: $enabledOnly
      first: $first
    ) {
      nodes {
        id
        name
        enabled
        primaryKeyName
        runsAllRules
        sourceCount
        ruleCount
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const rlrFailures = /* GraphQL */ `
  query (
    $id: Int!
    $page: Int!
    $pageSize: Int!
    $pkFilter: String
    $ruleStandardColumns: [Long!]!
    $ruleStandardIdFilter: [Long!]
    $sortMostFailuresToLeast: Boolean
  ) {
    rlrFailures(
      id: $id
      page: $page
      pageSize: $pageSize
      pkFilter: $pkFilter
      ruleStandardColumns: $ruleStandardColumns
      ruleStandardIdFilter: $ruleStandardIdFilter
      sortMostFailuresToLeast: $sortMostFailuresToLeast
    ) {
      pageCount
      nodes {
        primaryKey
        rules {
          ruleStandardId
          mapped
          failures {
            rowKey
            serializedPrimaryKeys
            ruleInstanceVersionId
            ruleStandardId
            resultCode
            ruleName
            failureMappingId
            failureCalculationMappingId
          }
        }
      }
    }
  }
`;

export const dataSourceRowsAndFailuresByPK = /* GraphQL */ `
  query ($rlrId: Int!, $pk: String!, $onlyFailingColumns: Boolean! = false) {
    dataSourceRowsAndFailuresByPK(
      rlrId: $rlrId
      pk: $pk
      onlyFailingColumns: $onlyFailingColumns
    ) {
      nodes {
        id
        cells {
          column
          isFailure
          value
        }
        dataSourceId
      }
    }
  }
`;

export const dataSourceRowByPK = /* GraphQL */ `
  query ($rlrId: Int!, $pk: String!) {
    dataSourceRowByPK(rlrId: $rlrId, pk: $pk) {
      nodes {
        id
        cells {
          key
          value
        }
        dataSourceId
      }
    }
  }
`;

export const dataSourceRowsByRowKeyAndRule = /* GraphQL */ `
  query (
    $rlrId: Int!
    $rowKey: String!
    $ruleInstanceVersionId: Long!
    $onlyFailingColumns: Boolean! = false
  ) {
    dataSourceRowsByRowKeyAndRule(
      rlrId: $rlrId
      rowKey: $rowKey
      ruleInstanceVersionId: $ruleInstanceVersionId
      onlyFailingColumns: $onlyFailingColumns
    ) {
      nodes {
        id
        cells {
          column
          value
          isFailure
        }
        dataSourceId
      }
    }
  }
`;

export const recordLevelReportById = /* GraphQL */ `
  query ($id: Int!, $where: BusinessRuleInstanceFilterInput) {
    recordLevelReportById(id: $id) {
      id
      name
      enabled
      groupingKeyAlias
      runsAllRules
      sources {
        sourceId
        columnId
        source {
          columns {
            enabled
            id
            name
          }
          name
          id
          latestReport {
            timestamp
            refreshSummaryId
            refreshSummary {
              createdOn
              resultState
            }
            qualityScore {
              score
            }
          }
          ruleInstances(where: $where) {
            totalCount
            edges {
              cursor
              node {
                standardId
                priority
                approvalState
                dataSourceId
                enabledState
                id
                latestVersion {
                  id
                  standardVersion {
                    id
                    standard {
                      id
                      enabled
                      latestVersion {
                        id
                      }
                    }
                  }
                  standardVersionId
                }
                title
              }
            }
          }
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              description
              id
            }
            tagId
            type
          }
        }
      }
      rules {
        rule {
          id
          name
          instances {
            id
            title
            enabledState
            dataSource {
              enabled
              id
              name
            }
            latestVersion {
              id
              standardVersionId
              standardVersion {
                standard {
                  id
                  latestVersion {
                    id
                  }
                }
              }
            }
          }
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              description
              id
            }
            tagId
            type
          }
        }
      }
    }
  }
`;

export const rlrStatus = /* GraphQL */ `
  query ($rlrId: Int!) {
    rlrStatus(rlrId: $rlrId) {
      inProgress
      lastUpdatedTime
      totalFailures
      failuresByRuleStandardId {
        ruleStandardId
        count
      }
      reportIsMissingFailures
    }
  }
`;

export const rlrFailureDetailsByPK = /* GraphQL */ `
  query ($rlrId: Int!, $pk: String!) {
    rlrFailureDetailsByPK(rlrId: $rlrId, pk: $pk) {
      nodes {
        failure {
          rowKey
          serializedPrimaryKeys
          ruleInstanceVersionId
          ruleStandardId
          resultCode
          ruleName
          failureMappingId
          failureCalculationMappingId
        }
        sourceId
        sourceName
        columnName
        columnValue
      }
    }
  }
`;
