import React, { useEffect, useState, useCallback } from "react";
import useFailureContextNotifications from "../../../Hooks/useFailureContextNotifications";
import Toggle from "../../../components/Toggle";
import { retrieveFailureContext } from "../../../api/dataSourceMutations";
import { useApi } from "../../../api/useApi";
import PagedTable from "../../../components/Table/PagedTable";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import { createCsvReportFailureFile } from "../../../api/dataSourceMutations";
import Notification from "../../../components/Notification";
import Button from "../../../components/Button";
import Spinner from "../../../components/Loaders/Spinner";
import { dataSourceStandardInstanceVersion } from "../../../api/dataSourceQueries";
import SplashLoader from "../../../components/Loaders/SplashLoader";
import { NotificationLoading } from "../../../components/Notification/NotificationLoading";
import { TableLoading } from "../../../components/Table/elements";
import { ruleStandardById } from "../../../common/paths";
import StyledLink from "../../../components/StyledLink";
// Corresponds to enum values in RowValueFontStyle.cs
const RowValueFontStyle = {
  1: "normal",
  2: "italic",
};

const FailureContextTable = ({
  apiData,
  dataSourceRefreshSummaryId,
  ruleInstanceVersionId,
  showMapping,
  setShowMapping,
}) => {
  //Get DataSource By Id for pinning
  const [{ loading: loadingMapped, data: mappingData }] = useApi(
    dataSourceStandardInstanceVersion,
    {
      id: Number(apiData?.dataSource?.id),
      instanceVersionId: Number(ruleInstanceVersionId),
    }
  );

  const businessRules = mappingData
    ? mappingData?.dataSource?.ruleInstanceVersionById?.standardVersion?.fragments
        .filter((frag) => frag.typeInformation[0].typeValue === 3) // First Fragment of an Operation has a typeValue = 3
        .map((operationFrag) => operationFrag.typeInformation[1].typeValue)
    : []; // Second Fragment of an Operation's typeValue is the businessRule

  const isAReconciliationRule =
    businessRules.includes(14) || businessRules.includes(15);

  const ruleFailures =
    apiData?.dataSource?.reportById?.ruleFailures?.failures ?? [];

  const failureDetails = ruleFailures.find(
    (rf) => rf.failedRuleInstanceVersionId === ruleInstanceVersionId
  );

  const [fetchId, setFetchId] = useState();
  const [endCursor, setEndCursor] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState("All Failures");
  const [contextType, setContextType] = useState();
  const [{ errors, data: apiRowData }, doFetch] = useApi();
  const [showExport, setShowExport] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [limitColumnsState, setLimitColumnsState] = useState(true);
  const { failureContextNotification } =
    useFailureContextNotifications("failureContext");

  useEffect(() => {
    if (apiRowData) {
      const fetchIdToStore = apiRowData?.retrieveFailureContext;
      setFetchId(fetchIdToStore);
    }
  }, [apiRowData]);

  useEffect(() => {
    if (failureContextNotification) {
      // const payload = failureContextNotification.payload;
      const matchLastRequest =
        fetchId === failureContextNotification.payload.RequestId;
      if (matchLastRequest) {
        if (failureContextNotification?.payload?.ErrorMessage != null) {
          const errorMessage = [
            {
              message: failureContextNotification?.payload?.ErrorMessage,
            },
          ];
          setErrorMessage(errorMessage);
          setIsLoading(false);
        } else if (failureContextNotification?.payload?.FailureContext) {
          const failureContext =
            failureContextNotification?.payload?.FailureContext;

          const columnsData = failureContext?.columnsContext?.map((col) => {
            return {
              Header: col?.name ? col?.name : " ",
              id: col.columnIndex,
              style: { whiteSpace: "unset" },
              Cell: ({ row: { original } }) => {
                return (
                  <div
                    style={{
                      color: original[col.columnIndex]?.failed
                        ? "red"
                        : "inherit",
                      fontStyle:
                        RowValueFontStyle[
                          original[col.columnIndex]?.fontStyle
                        ] ?? "normal",
                    }}
                  >
                    {original[col.columnIndex]?.value}
                  </div>
                );
              },
            };
          });
          if (
            failureContext?.previousAsOfDate &&
            failureContext?.currentAsOfDate
          ) {
            setContextType({
              previousAsOfDate: failureContext?.previousAsOfDate,
              currentAsOfDate: failureContext?.currentAsOfDate,
            });
          } else if (failureContext?.currentAsOfDate) {
            setContextType({
              previousAsOfDate: null,
              currentAsOfDate: failureContext?.currentAsOfDate,
            });
          } else {
            setContextType(null);
          }

          setEndCursor(failureContext?.endCursor);
          setColumnData(columnsData);

          setRowData(failureContext?.rowsContext);
          setTotal(failureContext?.totalCount);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    }
  }, [failureContextNotification, fetchId]);

  let totalCount = total;
  let typeConversion = "";

  if (view === "All Failures") {
    typeConversion = "ALL_FAILURES";
  } else if (view === "Null") {
    typeConversion = "NULL_FAILURE";
  } else if (view === "Incorrect") {
    typeConversion = "INCORRECT_VALUE_FAILURE";
  } else if (view === "No Matching") {
    typeConversion = "NO_MATCHING_ROW";
  } else if (view === "Successes") {
    typeConversion = "ALL_SUCCESSES";
  } else if (view === "All") {
    typeConversion = "ALL";
  } else if (view === "Clone") {
    typeConversion = "CLONE_DATA_SOURCE";
  } else if (view === "Errors") {
    typeConversion = "ERRORS";
  }

  const fetchData = useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: retrieveFailureContext,
        variables: {
          ruleInstanceVersionId: ruleInstanceVersionId,
          dataSourceRefreshSummaryId: dataSourceRefreshSummaryId,
          failureType: typeConversion,
          limitColumns: limitColumnsState,
          cursor: {
            count: pageSize,
            lastId: cursor ?? null,
            sortAscending: null,
            sortField: null,
          },
        },
      });
      setIsLoading(true);
    },
    [
      dataSourceRefreshSummaryId,
      doFetch,
      limitColumnsState,
      ruleInstanceVersionId,
      typeConversion,
    ]
  );

  const toggleData = [
    {
      name: "All Failures",
      data: failureDetails?.failureCount,
      action: () => setView("All Failures"),
    },
    {
      name: "Null",
      data: failureDetails?.nullFailureCount,
      action: () => setView("Null"),
    },
    {
      name: "Incorrect",
      data: failureDetails?.incorrectValueFailureCount,
      action: () => setView("Incorrect"),
    },
    {
      name: "No Matching",
      data: failureDetails?.noMatchingRowFailureCount,
      action: () => setView("No Matching"),
    },
    {
      name: "Errors",
      data: failureDetails?.errorCount,
      action: () => setView("Errors"),
    },
    {
      name: "Successes",
      data: failureDetails?.successCount,
      action: () => setView("Successes"),
    },
    {
      name: "All",
      data: failureDetails?.successCount + failureDetails?.failureCount,
      action: () => setView("All"),
    },
  ];

  const ShowToggle = [
    {
      name: showMapping ? "Hide Policy" : "Show Policy",
      action: () => setShowMapping((prev) => !prev),
    },
  ];

  let datesToRender = "";
  if (contextType?.previousAsOfDate && contextType?.currentAsOfDate) {
    datesToRender = `${contextType?.previousAsOfDate} - ${contextType.currentAsOfDate}`;
  } else if (contextType?.currentAsOfDate) {
    datesToRender = `${contextType?.currentAsOfDate}`;
  }

  const [{ loading: csvLoading, errors: csvErrors, data: csvData }, createCSV] =
    useApi();

  const resetExport = useCallback(() => {
    setShowExport(false);
  }, [setShowExport]);

  useEffect(() => {
    if (csvData) {
      setShowExport(true);
    }
  }, [csvData, setShowExport]);

  const createCSVReport = useCallback(
    ({ failureType, dataSourceRefreshSummaryId, ruleInstanceVersionId }) => {
      createCSV({
        query: createCsvReportFailureFile,
        variables: {
          dataSourceRefreshSummaryId: dataSourceRefreshSummaryId,
          failureType: failureType,
          ruleInstanceVersionId: ruleInstanceVersionId,
        },
      });
    },
    [createCSV]
  );

  function convertType(view) {
    switch (view) {
      default:
        return "ALL_FAILURES";
      case "Null": {
        return "NULL_FAILURE";
      }
      case "Incorrect": {
        return "INCORRECT_VALUE_FAILURE";
      }
      case "No Matching": {
        return "NO_MATCHING_ROW";
      }
      case "Successes": {
        return "ALL_SUCCESSES";
      }
      case "All": {
        return "ALL";
      }
      case "Clone": {
        return "CLONE_DATA_SOURCE";
      }
      case "Errors": {
        return "ERRORS";
      }
    }
  }

  const failureType = convertType(view);

  let failedColumnsByRule = [];
  const columnEntries =
    apiData?.dataSource?.reportById?.failureDetails?.columnEntries ?? [];
  columnEntries?.forEach((ce) => {
    if (ce?.name) {
      ce?.details.forEach((details) => {
        if (
          !failedColumnsByRule.find(
            (cbr) =>
              cbr.columnName === ce?.name &&
              cbr.policyInstanceName === details?.ruleStandardInstancedName
          )
        ) {
          failedColumnsByRule.push({
            columnName: ce?.name,
            policyInstanceName: details?.ruleStandardInstancedName,
          });
        }
      });
    }
  });

  const filteredPinnedColumns =
    failedColumnsByRule
      .filter((cbr) => {
        return (
          cbr?.policyInstanceName === failureDetails?.failedRuleInstanceName
        );
      })
      .map((fc) => {
        return {
          label: fc?.columnName,
          value: apiData?.dataSource?.columns?.find(
            (c) => c?.name === fc?.columnName
          )?.ordinal,
        };
      })
      .filter((pinned) => {
        return pinned.value !== undefined;
      }) ?? [];

  if (loadingMapped || !mappingData) {
    return (
      <TableLoading>
        <SplashLoader text="Loading Results" />
      </TableLoading>
    );
  }

  return (
    <>
      {showExport ? (
        <NotificationLoading>
          <Notification
            closeCallBack={resetExport}
            text="Your data extract is being prepared; an email will be sent to you when the extract is ready to download."
          />
        </NotificationLoading>
      ) : null}

      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Toggle toggleState={view} toggleData={toggleData} hasCounts={true} />
          <div style={{ marginLeft: "auto" }}>
            <Button
              type="button"
              title={`Export ${view}`}
              list={"true"}
              disabled={
                csvLoading ||
                toggleData.find((td) => td?.name === view)?.data === 0
              }
              onClick={() =>
                createCSVReport({
                  failureType: failureType,
                  dataSourceRefreshSummaryId: dataSourceRefreshSummaryId,
                  ruleInstanceVersionId: ruleInstanceVersionId,
                })
              }
            >
              {csvLoading ? <Spinner /> : `Export ${view}`}
            </Button>

            {showMapping ? null : (
              <Toggle
                toggleData={ShowToggle}
                title="Toggle Policy"
                type="button"
                toggleState={showMapping}
                bool={true}
                onClick={() => setShowMapping((prev) => !prev)}
              >
                Show Policy
              </Toggle>
            )}
          </div>
        </div>
      </div>

      {contextType ? (
        <div style={{ marginTop: "1rem", fontWeight: "bold" }}>
          {datesToRender}
        </div>
      ) : null}

      {failureDetails ? (
        <>
          <div style={{ marginTop: "1rem", fontSize: ".8rem" }}>
            Policy Instance Name
          </div>
          <h3>
            <StyledLink
              to={ruleStandardById(failureDetails?.failedRuleStandardId)}
            >
              {failureDetails?.failedRuleInstanceName}
            </StyledLink>
          </h3>

          <div style={{ marginTop: "1rem", fontSize: ".8rem" }}>
            Policy Template Name:{" "}
            <div>
              <StyledLink
                to={ruleStandardById(failureDetails?.failedRuleStandardId)}
              >
                {failureDetails?.failedRuleStandardName}
              </StyledLink>
            </div>
          </div>
        </>
      ) : null}

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <h3 style={{ marginTop: "1rem" }}>Failures</h3>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Button
            type="button"
            onClick={() => setLimitColumnsState((prev) => !prev)}
            title="Toggle this button to show only mapped columns to your policy"
          >
            {limitColumnsState
              ? "Show All Columns"
              : "Show Only Mapped Columns"}
          </Button>
        </div>
      </div>

      {errorMessage ? <ErrorMessages errors={errorMessage} /> : null}
      {errors ? <ErrorMessages errors={errors} /> : null}
      {csvErrors ? <ErrorMessages errors={csvErrors} /> : null}
      <PagedTable
        fetchData={fetchData}
        endCursor={endCursor}
        loading={isLoading}
        totalCount={totalCount}
        data={rowData}
        columns={columnData}
        defaultPageSize={50}
        maxSize={50} //max 50 to match the backend limitation
        key={view}
        pinning={true}
        pinned={isAReconciliationRule ? [] : filteredPinnedColumns}
      />
    </>
  );
};

export default FailureContextTable;
