import React, { useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useApi as useApiRedux } from "../api/useApiRedux";

// Utility function to parse query parameters
const useQuery = () => {
  const { search } = useLocation();
  // Directly use 'search' as dependency for useMemo
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const IngressContext = React.createContext();

const IngressActions = (props) => {
  const query = useQuery();

  const userAction = useMemo(() => query.get("userAction"), [query]); // Use 'queryString' as the dependency

  const exportType = useMemo(() => query.get("exportType"), [query]);
  const identifier = useMemo(() => query.get("identifier"), [query]);

  const actionParams = useMemo(() => {
    let params = {};
    query.forEach((value, key) => {
      if (key !== "userAction") {
        params[key] = value;
      }
    });
    return params;
    // Use the 'queryString' variable in the dependency array
  }, [query]);

  const [_, callAction] = useApiRedux();

  const handleUserAction = useCallback(() => {
    if (userAction === "download") {
      callAction({
        query: `/failureDetails/retrieve`,
        params: actionParams,
        method: "GET",
      });
    } else if (
      userAction === "downloadSharedExport" &&
      exportType &&
      identifier
    ) {
      // New handling for file download without userAction
      callAction({
        query: `/sharedExport/retrieve`,
        params: actionParams,
        method: "GET",
      });
    }
    // Add more userAction cases as needed
  }, [userAction, actionParams, callAction]);

  const value = useMemo(() => ({}), []);

  useEffect(() => {
    handleUserAction();
  }, [handleUserAction]);

  return (
    <IngressContext.Provider value={value}>
      {props.children}
    </IngressContext.Provider>
  );
};

export default IngressActions;
