import React, { useContext } from "react";
import {
  MdVpnKey,
  MdLibraryBooks,
  MdVerifiedUser,
  MdGroup,
  MdErrorOutline,
  MdSettings,
  MdFeed,
  MdTag,
  MdPageview,
} from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import { TiFlowMerge } from "react-icons/ti";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { AuthContext } from "../../../contexts/AuthContext";
import * as paths from "../../../common/paths";
import { useApi } from "../../../api/useApi";
import { rlrFeatureEnabled } from "../../../api/ruleQueries";
import { MdWorkspaces } from "react-icons/md";

const MenuIcon = styled.div`
  font-size: 1.2em;
  margin-right: ${(props) => (props.menuState ? "10px" : "")};
  color: ${(props) => props.theme.menuText};
  opacity: 0.5;
`;

const UserMenuButton = styled(NavLink)`
  display: flex;
  align-items: center;
  background: transparent;
  color: ${(props) => props.theme.menuText};
  padding: 1rem;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  margin-right: 0;
  margin-left: 0;
  border-left: 4px solid transparent;
  font-size: 1em;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.menuHoverActive};
  }
  &.active {
    background: ${(props) => props.theme.menuHoverActive};
    border-left: 4px solid ${(props) => props.theme.onSecondarySurface};
    ${MenuIcon} {
      opacity: 1;
    }
  }
`;

const NavList = styled.div``;

function UserMenuItem({ menuState }) {
  const { user } = useContext(AuthContext);
  const [{ data: rlrIsEnabledData }] = useApi(rlrFeatureEnabled);
  const { href: url } = window.location;
  // eslint-disable-next-line
  const [subDomain] = /:\/\/([^\/]+)/.exec(url)?.[1]?.split(".") ?? [""];

  const showTransfer = [
    "performance",
    "pentest",
    "citco",
    "exp",
    "equiant",
    "test",
    "fay",
    "cenlar",
    "test2",
    "test3",
    "test4",
    "staging",
    "localhost:5000",
    "demo",
    "demo2",
  ].includes(subDomain.toLowerCase());

  const showWorkspaces = [
    "pentest",
    "exp",
    "test",
    "test2",
    "test3",
    "test4",
    "staging",
    "localhost:5000",
    "demo",
    "demo2",
  ].includes(subDomain.toLowerCase());

  return (
    <NavList>
      {showWorkspaces && (
        <UserMenuButton to={paths.workSpaces()}>
          <MenuIcon menuState={menuState}>
            <MdWorkspaces />
          </MenuIcon>
          {menuState && "Workspaces"}
        </UserMenuButton>
      )}

      <UserMenuButton to={paths.workGroups()}>
        <MenuIcon menuState={menuState}>
          <MdGroup />
        </MenuIcon>
        {menuState && "Workgroups"}
      </UserMenuButton>

      {user && user.role >= 2 && (
        <UserMenuButton to={paths.dataConnections()}>
          <MenuIcon menuState={menuState}>
            <MdVpnKey />
          </MenuIcon>
          {menuState && "Connections"}
        </UserMenuButton>
      )}

      <UserMenuButton to={paths.dataSources()}>
        <MenuIcon menuState={menuState}>
          <FaDatabase />
        </MenuIcon>
        {menuState && "Sources"}
      </UserMenuButton>

      <UserMenuButton
        //Check active by looking at pathname
        isActive={(match, location) => {
          let pathStrings = location.pathname.split("/");
          if (match) {
            return true;
          } else if (pathStrings[2] === "filters") {
            return true;
          } else {
            return false;
          }
        }}
        to={paths.dataQualityRules()}
      >
        <MenuIcon menuState={menuState}>
          <MdLibraryBooks />
        </MenuIcon>
        {menuState && "Policies"}
      </UserMenuButton>

      {rlrIsEnabledData?.rlrFeatureEnabled && (
        <UserMenuButton to={"/dynamicviews"}>
          <MenuIcon menuState={menuState}>
            <MdPageview />
          </MenuIcon>
          {menuState && "Dynamic Views"}
        </UserMenuButton>
      )}

      {user && user.role === 3 && (
        <UserMenuButton to={paths.workflows()}>
          <MenuIcon menuState={menuState}>
            <TiFlowMerge />
          </MenuIcon>
          {menuState && "Workflows"}
        </UserMenuButton>
      )}

      <UserMenuButton to={paths.issues()}>
        <MenuIcon menuState={menuState}>
          <MdErrorOutline />
        </MenuIcon>
        {menuState && "Issues"}
      </UserMenuButton>
      {user && showTransfer && (
        <UserMenuButton to={paths.feeds()}>
          <MenuIcon menuState={menuState}>
            <MdFeed />
          </MenuIcon>
          {menuState && "Transformations"}
        </UserMenuButton>
      )}

      <UserMenuButton to={"/tags"}>
        <MenuIcon menuState={menuState}>
          <MdTag />
        </MenuIcon>
        {menuState && "Tags"}
      </UserMenuButton>

      <UserMenuButton menuState={menuState} to={"/settings"}>
        <MenuIcon menuState={menuState}>
          <MdSettings />
        </MenuIcon>
        {menuState && "Settings"}
      </UserMenuButton>

      {user && user.role === 3 && (
        <UserMenuButton to={paths.admin()}>
          <MenuIcon menuState={menuState}>
            <MdVerifiedUser />
          </MenuIcon>
          {menuState && "Admin"}
        </UserMenuButton>
      )}
    </NavList>
  );
}

export default UserMenuItem;
