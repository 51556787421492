import React, { useEffect } from "react";
import Card from "../../components/Card";
import { Route, NavLink, useParams, Switch } from "react-router-dom";
import styled from "styled-components/macro";
import {
  dataQualityRules,
  dataSourceFilters,
  filtersHistory,
  standardsHistory,
} from "../../common/paths";
import { MdArrowBack, MdHistory } from "react-icons/md";
import ActionWrapper from "../../components/ActionsWrapper";
import StyledLink from "../../components/StyledLink";
import DataQualityRulesForm from "../DataQualityRulesPage/RulesForm";
import Sources from "./Sources";
import { useApi } from "../../api/useApi";
import { businessRuleStandardById } from "../../api/ruleQueries";
import SplashLoader from "../../components/Loaders/SplashLoader";

const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  padding: 1.2rem;
`;

const TabLink = styled(NavLink)`
  padding: 1rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-bottom: 2px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
`;

const Body = ({ rule, isFilter }) => {
  const sourceInstances = rule?.instances ?? [];
  const filteredSourceInstances = sourceInstances.filter(
    (instance) => instance?.enabledState != "DISABLED" && instance?.dataSource?.enabled
  );

  return (
    <>
      <Tabs>
        <TabLink exact to={`/policies/filters/${rule?.id}`}>
          Mappings
        </TabLink>
        <TabLink
          exact
          to={`/policies/filters/${rule?.id}/edit`}
          title="Edit Filter"
        >
          Edit Filter
        </TabLink>
      </Tabs>
      {/* TODO: css hack for container around edit form temp should figure out px issue */}
      <Switch>
        <Route
          path={`/policies/filters/:ruleId/edit`}
          component={(props) => (
            <div style={{ marginTop: "1px" }}>
              <DataQualityRulesForm {...props} />
            </div>
          )}
        />

        <Route
          path={`/policies/filters/:ruleId`}
          component={() => (
            <Sources
              sourceInstances={filteredSourceInstances}
              ruleId={rule?.id}
              isFilter={isFilter}
            />
          )}
        />
      </Switch>
    </>
  );
};

//Actions Section of Widget
function Actions({ isFilter, ruleId }) {
  return (
    <>
      <ActionWrapper>
        <StyledLink
          title="Back to List"
          to={isFilter ? dataSourceFilters() : dataQualityRules()}
        >
          <MdArrowBack />
        </StyledLink>
      </ActionWrapper>

      <ActionWrapper>
        <StyledLink
          title="History"
          to={isFilter ? filtersHistory(ruleId) : standardsHistory(ruleId)}
        >
          <MdHistory />
        </StyledLink>
      </ActionWrapper>
    </>
  );
}

const CardWrapper = () => {
  let params = useParams();

  const ruleId = Number(params?.ruleId);

  const [{ loading, data }, ruleStandardFetch] = useApi();

  const rule = data?.businessRuleStandardById;

  useEffect(() => {
    if (ruleId) {
      const variables = {
        standardId: ruleId,
      };
      ruleStandardFetch({ query: businessRuleStandardById, variables });
    }
  }, [ruleId, ruleStandardFetch]);

  const isFilter = true;
  if (loading) return <SplashLoader text={"Loading Filter"} />;
  return (
    <Card
      title={rule?.name}
      titleDescription={isFilter ? "Filter" : "Rule"}
      body={() => <Body rule={rule} isFilter={isFilter} />}
      actions={() => Actions({ isFilter, ruleId })}
    />
  );
};

export default CardWrapper;
