import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import HomePage from "./HomePage";
import ProtectedRoute from "./ProtectedRoute";
import DataQualityRulesPage from "./DataQualityRulesPage";
import DataConnectionsPage from "./DataConnectionsPage";

import DataQualityRulesForm from "./DataQualityRulesPage/RulesForm";
import DataSourcesPage from "./DataSourcesPage";
import SourcesArchivePage from "./DataSourcesPage/SourcesArchivePage";
import RuleStandardPage from "./RuleStandardPage";
import FilterPage from "./RuleStandardPage/filterPage";
import ConnectionDetails from "./DataConnectionsPage/ConnectionDetails";
import AdminPage from "./AdminPage";
import AdminProductConfig from "./AdminPage/AdminProductConfig";
import LoginRedirectRoute from "./LoginRedirect";
// import DataSourceRemediation from "./DataSourceRemediation";
import Settings from "./Settings";
import DataSourceDashboard from "./DashboardPage";
import ConnectionForm from "./DataConnectionsPage/ConnectionForm";
import Reports from "./DataSourcesPage/Reports";
import ReportById from "./DataSourcesPage/Reports/ReportById";
import WorkGroups from "./WorkgroupPage";
import ProfileDashboard from "./ProfileDash/ProfileDashboard";
// import Welcome from "./Welcome";
import DataQualityRulesHistory from "./DataQualityRulesPage/RulesHistory";
import ConsentRedirect from "./ConsentRedirect";
import WorkGroupForm from "../components/Forms/Workgroups/WorkGroupForm";
import ManageGroupForm from "../components/Forms/Workgroups/ManageGroupForm";

import Workflows from "./WorkflowsPage/WorkflowsPage";
import WorkflowsArchivePage from "./WorkflowsPage/WorkflowsArchivePage";
import WorkflowCreate from "./WorkflowsPage/WorkflowCreate";
import WorkflowById from "./WorkflowsPage/WorkflowById";

import Issues from "./IssuesPage/IssuesPage";
import FeedsPage from "./FeedsPage/FeedsPage";

import TagsPage from "./TagsPage/TagPage";

import FeedsListHistory from "./FeedsPage/FeedsListHistory";

import ListPolicyDynamicViews from "../components/Widgets/PolicyWidgets/ListPolicyDynamicViews";
import PolicyDynamicViewForm from "../components/Widgets/PolicyWidgets/PolicyDynamicViewForm";
import DynamicView from "../components/Widgets/PolicyWidgets/DynamicView";

import Workspaces from "./Workspaces/WorkspacesMain";
import Workspace from "./Workspaces/Workspace";

// The Wizard
// import FeedForm from "./FeedsPage/FeedForm";
import FeedFormMinimal from "./FeedsPage/FeedFormMinimal";

class ContentPage extends Component {
  render() {
    return (
      <Switch>
        {/* Business Rules */}

        <ProtectedRoute
          path="/policies/rules/mapped"
          component={DataQualityRulesPage}
        />

        <ProtectedRoute
          path="/policies/rules/create"
          component={DataQualityRulesForm}
        />
        <ProtectedRoute
          path="/policies/filters/create"
          component={DataQualityRulesForm}
        />

        <ProtectedRoute
          path="/policies/rules/:ruleId/history"
          component={DataQualityRulesHistory}
        />
        <ProtectedRoute
          path="/policies/filters/:ruleId/history"
          component={DataQualityRulesHistory}
        />

        <ProtectedRoute
          path="/policies/filters/:ruleId"
          component={FilterPage}
        />

        <ProtectedRoute
          path="/policies/rules/:ruleId"
          component={RuleStandardPage}
        />

        <ProtectedRoute path="/policies" component={DataQualityRulesPage} />

        {/* Dynamic Views */}

        <ProtectedRoute
          path={`/dynamicviews/new`}
          component={PolicyDynamicViewForm}
        />

        <ProtectedRoute
          path={`/dynamicviews/:viewId/edit`}
          component={PolicyDynamicViewForm}
        />

        <ProtectedRoute
          path={`/dynamicviews/:viewId`}
          component={DynamicView}
        />

        <ProtectedRoute
          path={`/dynamicviews`}
          component={ListPolicyDynamicViews}
        />

        {/* Data Connections */}

        <ProtectedRoute
          path="/connections/create"
          component={ConnectionForm}
          requiredRole={"SUPERVISOR"}
        />

        <ProtectedRoute
          path="/connections/:connectionId"
          component={ConnectionDetails}
          requiredRole={"SUPERVISOR"}
        />

        <ProtectedRoute
          path="/connections"
          component={DataConnectionsPage}
          requiredRole={"SUPERVISOR"}
        />

        {/* Data Sources */}
        {/* Remediation */}

        <ProtectedRoute
          path="/sources/archive"
          component={SourcesArchivePage}
        />

        <ProtectedRoute
          path="/sources/:sourceId/reports/:reportId"
          component={ReportById}
        />

        <ProtectedRoute path="/sources/:sourceId/reports" component={Reports} />

        {/* Disable Remediation for now*/}
        {/* <ProtectedRoute
          path="/sources/:sourceId/details/:failedRuleStandardId/:failedRuleInstanceId/:failedRuleInstanceVersionId"
          component={DataSourceRemediation}
        /> */}

        {/* <ProtectedRoute
          path="/sources/:sourceId/(standards|filters|constraints|alerts|settings|permissions)"
          component={DataSourceManage}
        /> */}

        <ProtectedRoute
          path="/sources/:sourceId"
          component={DataSourceDashboard}
        />

        <ProtectedRoute path="/sources" component={DataSourcesPage} />

        <ProtectedRoute
          path="/workflows/archive"
          component={WorkflowsArchivePage}
        />

        <ProtectedRoute path="/workflows/create" component={WorkflowCreate} />

        <ProtectedRoute
          path="/workflows/:workflowId/edit"
          component={WorkflowCreate}
        />

        <ProtectedRoute
          path="/workflows/:workflowId"
          component={WorkflowById}
        />

        <ProtectedRoute
          path="/workflows"
          component={Workflows}
          requiredRole={"ADMINISTRATOR"}
        />

        {/* Issues */}
        <ProtectedRoute path="/issues" component={Issues} />

        {/* Tags */}
        <ProtectedRoute path="/tags" component={TagsPage} />

        {/* Admin */}
        <ProtectedRoute
          path="/admin/production-config"
          component={AdminProductConfig}
          requiredRole={"ADMINISTRATOR"}
        />

        <ProtectedRoute
          path="/admin"
          component={AdminPage}
          requiredRole={"ADMINISTRATOR"}
        />

        {/* WorkGroup */}

        <ProtectedRoute path="/workgroups/add" component={WorkGroupForm} />
        <ProtectedRoute
          path="/workgroups/:workgroupId/manage"
          component={ManageGroupForm}
        />

        {/* Feeds */}
        <ProtectedRoute path="/feeds/archive" component={FeedsPage} />
        <ProtectedRoute path="/feeds/inputsources" component={FeedsPage} />
        <ProtectedRoute path="/feeds/outputsources" component={FeedsPage} />
        <ProtectedRoute path="/feeds/new" component={FeedFormMinimal} />
        <ProtectedRoute
          path="/feeds/:feedId/clone"
          component={FeedFormMinimal}
        />
        <ProtectedRoute
          path="/feeds/:feedId/history"
          component={FeedsListHistory}
        />
        {/* Workspaces */}
        <ProtectedRoute path="/workspaces/:workspaceId" component={Workspace} />
        <ProtectedRoute path="/workspaces" component={Workspaces} />

        <ProtectedRoute path="/feeds/:feedId" component={FeedFormMinimal} />
        <ProtectedRoute path="/feeds" component={FeedsPage} />

        <ProtectedRoute path="/workgroups" component={WorkGroups} />

        <ProtectedRoute path="/welcome" component={ProfileDashboard} />

        <ProtectedRoute path="/settings" component={Settings} />

        <LoginRedirectRoute path="/login_redirect" />

        <ConsentRedirect path="/consent_redirect" />

        <Route path="/" component={HomePage} />
      </Switch>
    );
  }
}

export default withRouter(ContentPage);
