// Get Available Connections
export const availableConnections = /* GraphQL */ `
  query ($first: Int, $after: String) {
    availableConnections(first: $first, after: $after) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursorConnectionFilter
        node {
          context
          id
          enabled
          name
          details {
            createdOn
            connectionId
          }
          serverType
          dataSources {
            enabled
            id
          }
        }
      }
    }
  }
`;

// List Connections
export const connectionsList = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $where: ConnectionFilterInput
    $order: [ConnectionSortInput!]
  ) {
    availableConnections(
      first: $first
      after: $after
      where: $where
      order: $order
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          context
          id
          name
          enabled
          dataSources {
            id
          }
        }
      }
    }
  }
`;

export const egressConnectionsList = /* GraphQL */ `
  query ($first: Int, $after: String) {
    availableConnections(
      first: $first
      after: $after
      where: {
        id: { gt: 0 }
        enabled: { eq: true }
        serverType: { in: [AZURE_BLOB_STORAGE, SFTP, FTPS] }
      }
      order: { name: ASC }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          context
          id
          name
          enabled
          dataSources {
            id
          }
        }
      }
    }
  }
`;

// Connection by ID
export const connection = /* GraphQL */ `
  query ($id: Int!) {
    connection(id: $id) {
      id
      enabled
      name
      details {
        createdOn
        connectionId
      }
      ocrConnection {
        containerName
        enabled
        id
        inputPath
        isAutoSource
        namingConvention
        docProviderId
        destinationConnectionId
        identificationType
        customVisionProjectId
        customVisionIteration
        ocrDocuments {
          enabled
          id
          modelId
          modelName
          versions
          ocrConnectionId
          typeId
          docIdOnly
          pagesToRead
          candidateTypeIds
          locateOnlyTypeIds
          minimumPageCount
          minimumPredictionThreshold
          identificationType
        }
      }
      serverType
      permissions {
        accessingWorkGroupId
        permissionCode
      }
      dataSources {
        id
        name
        schema
        enabled
        reportStatus
        ingressInfo {
          id
        }
        egressInfo {
          id
        }
        ruleInstancesCount
        latestReport {
          refreshSummaryId
          refreshSummary {
            createdOn
          }
          qualityScore {
            score
          }
        }
        tagInstances {
          id
          remoteObjectId
          tag {
            name
            description
            id
          }
          tagId
          type
        }
      }
    }
  }
`;

// Get Connection By Id
export const connectionAlerts = /* GraphQL */ `
  query ($id: Int!) {
    connection(id: $id) {
      id
      alerts {
        id
        enabled
        instances {
          id
          alertId
          notificationType
          enabled
          members {
            id
            alertInstanceId
            userId
            user {
              displayName
              emailAddress
              id
              profilePhoto
            }
            workGroupId
            group {
              displayName
              id
            }
          }
        }
        threshold
        boolFlag
        alertType
      }
    }
  }
`;

// Ocr Connection by ID
export const ocrConnection = /* GraphQL */ `
  query ($id: Int!) {
    ocrConnection(id: $id) {
      id
      containerName
      inputPath
      enabled
      docProviderId
      namingConvention
      customVisionProjectId
      customVisionIteration
      identificationType
      ocrDocuments {
        id
        modelName
        modelId
        typeId
        versions
        candidateTypeIds
        locateOnlyTypeIds
        enabled
        docIdOnly
        pagesToRead
        minimumPageCount
        minimumPredictionThreshold
        identificationType
        ocrConnectionId
      }
    }
  }
`;

export const cubeDimensions = /* GraphQL */ `
  query ($id: Int!) {
    measures
    dimensions {
      hierarchies
    }
  }
`;

// OCR Train

export const customVisionLabels = /* GraphQL */ `
  query ($projectId: UUID!, $docProviderId: Int!) {
    customVisionLabels(projectId: $projectId, docProviderId: $docProviderId) {
      id
      label
    }
  }
`;
