// Gets all Servicer Transfer Feeds
export const getServicerTransferFeedById = /* GraphQL */ `
  query ($id: Int!) {
    servicerTransferFeedById(id: $id) {
      id
      name
      destinationDataSourceId
      sourceDataSourceIds
      primarySourceDataSourceId
      scheduledJob {
        id
        name
        description
        enabled
        jobType
        recurrenceInHours
        maxExecutionTimeInHours
        disabledOn
        workingDayObservation
        timeZoneUtcOffset
      }
      activeMonitoring
      nextScheduledReport
      destinationDataSource {
        id
        name
        connectionId
        columns {
          dataSourceId
          dataType
          enabled
          fullyQualifiedName
          id
          name
          flags
          ordinal
        }
        tagInstances {
          id
          remoteObjectId
          tag {
            name
            description
            id
          }
          tagId
          type
        }
      }
      sourceDataSources {
        id
        name
      }
      createdOn
      enabled
      inputCollectionId
      outputCollectionId
      permissions {
        accessingWorkGroupId
        permissionCode
      }
      transformers {
        id
        inputSubtype
        outputSubtype
        transformationType
        inputSubtype
        outputSubtype
        formattingString
        flags
        transformerArguments {
          argumentInstanceId
          argumentOrder
          instanceMappingId
          dataSourceColumnId
          column {
            id
            name
            flags
            uniqueValueCollectionId
            fullyQualifiedName
            dataSourceId
            dataSource {
              id
              tagInstances {
                id
                remoteObjectId
                tag {
                  name
                  description
                  id
                }
                tagId
                type
              }
            }
          }
        }
      }
      tagInstances {
        id
        remoteObjectId
        tag {
          name
          description
          id
        }
        tagId
        type
      }
    }
  }
`;

export const getServicerTransferFeedTagsById = /* GraphQL */ `
  query ($id: Int!) {
    servicerTransferFeedById(id: $id) {
      id
      tagInstances {
        id
        remoteObjectId
        tag {
          name
          description
          id
        }
        tagId
        type
      }
    }
  }
`;

// Gets Dictionary by Column Collection Id's
export const servicerTransferDictionaryByCollectionIds = /* GraphQL */ `
  query ($keyCollectionId: Int!, $valueCollectionId: Int!) {
    servicerTransferDictionaryByCollectionIds(
      keyCollectionId: $keyCollectionId
      valueCollectionId: $valueCollectionId
    ) {
      keyCollectionId
      valueCollectionId
      name
      id
      entries {
        key {
          id
          collectionId
          value
          description
        }
        value {
          id
          collectionId
          value
          description
        }
      }
    }
  }
`;

// Gets Dictionary by Column Collection Id's
export const allServicerTransferDictionaries = /* GraphQL */ `
  query {
    allServicerTransferDictionaries {
      id
      name
      keyCollection {
        name
        columns {
          name
        }
      }
      valueCollection {
        name
        columns {
          name
        }
      }
      entries {
        key {
          id
          collectionId
          value
          description
        }
        value {
          id
          collectionId
          value
          description
        }
      }
    }
  }
`;

// Gets Dictionary by Id
export const servicerTransferDictionaryById = /* GraphQL */ `
  query ($dictionaryId: Int!) {
    servicerTransferDictionaryById(dictionaryId: $dictionaryId) {
      id
      name
      keyCollectionId
      valueCollectionId
      entries {
        key {
          id
          collectionId
          value
          description
        }
        value {
          id
          collectionId
          value
          description
        }
      }
    }
  }
`;

export const getAllServicerTransferFeeds = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $tagFilter: CustomTagFilterInput!
    $order: [ServicerTransferFeedModelSortInput!]
    $where: ServicerTransferFeedModelFilterInput
  ) {
    allServicerTransferFeeds(
      first: $first
      after: $after
      tagFilter: $tagFilter
      order: $order
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          enabled
          destinationDataSourceId
          destinationDataSource {
            id
            name
            connectionId
            transferEgress {
              exportSource {
                id
                name
                enabled
                latestReport {
                  qualityScore {
                    score
                  }
                }
              }
            }
          }
          sourceDataSources {
            id
            name
          }
          transformers {
            id
            transformationType
            transformerArguments {
              dataSourceColumnId
              argumentOrder
              column {
                flags
                fullyQualifiedName
              }
            }
          }
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              description
              id
            }
            tagId
            type
          }
        }
      }
    }
  }
`;

// Get Available Servicer Transfer Feed Summary History
export const servicerTransferFeedHistory = /* GraphQL */ `
  query ($id: Int!, $first: Int, $after: String) {
    servicerTransferFeedById(id: $id) {
      name
      id
      servicerTransferFeedHistoryCount
      servicerTransferFeedHistory(first: $first, after: $after) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          cursor
          node {
            createdOn
            id
            resultState
            feedId
          }
        }
      }
    }
  }
`;

// Get Available Servicer Transfer Feed Summary History Count
export const servicerTransferFeedHistoryCount = /* GraphQL */ `
  query ($id: Int!) {
    servicerTransferFeedById(id: $id) {
      id
      name
      servicerTransferFeedHistoryCount
    }
  }
`;

// Get unique values from a column
export const getUniqueValuesByColumnId = /* GraphQL */ `
  query ($id: Int!) {
    uniqueValuesByColumnId(id: $id)
  }
`;

// Gets all Servicer Transfer Transformers
export const getAllServicerTransferTransformers = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [ServicerTransferFeedSortInput!]
  ) {
    allServicerTransferTransformers(
      first: $first
      after: $after
      order: $order
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          transformationType
          orderOfOperation
          destinationCulture
          modifier
          enabled
          transformerArguments {
            dataSourceColumnId
            argumentOrder
          }
        }
      }
    }
  }
`;

// Get Available Servicer Transfer Feed Summary History
export const allServicerTransferFeedsWithSummaries = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [ServicerTransferFeedModelSortInput!]
    $where: ServicerTransferFeedModelFilterInput
  ) {
    allServicerTransferFeedsWithSummaries(
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          createdOn
          id
          name
          sourceDataSources {
            name
            id
          }
          summaries {
            id
            feedId
            createdOn
            completedOn
            outputRowCount
            errorRowCount
          }
        }
      }
    }
  }
`;

// Get Available Servicer Transfer Feed Summary History
export const servicerTransferFeedByIdWithSummaries = /* GraphQL */ `
  query ($id: Int!) {
    servicerTransferFeedByIdWithSummaries(id: $id) {
      createdOn
      id
      name
      sourceDataSources {
        name
        id
      }
      summaries {
        id
        feedId
        createdOn
        completedOn
        outputRowCount
        errorRowCount
        inputFiles {
          completedOn
          dataSourceId
          dataSourceName
          id
          rowsProcessed
        }
      }
    }
  }
`;
