import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components/macro";

import {
  MdNotifications,
  MdNotificationsActive,
  MdClose,
  MdVpnKey,
  MdGrain,
  MdWarning,
  MdError,
  MdDelete,
} from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import { AuthContext } from "../../contexts/AuthContext";
import update from "immutability-helper";
import { useApi } from "../../api/useApi";
import { useHistory } from "react-router-dom";
import { dataSource, dataConnectionsSources } from "../../common/paths";
import useETLProviderCreateNotification from "../../Hooks/useETLProviderCreateNotification";
import useETLProviderInstanceCreateNotification from "../../Hooks/useETLProviderInstanceCreateNotification";
import useEtlPipelineAddCompleteEvent from "../../Hooks/useEtlPipelineAddCompleteEvent";
import useEtlProviderUpdateCompleted from "../../Hooks/useETLProviderUpdateNotification";
import useDataSourceNotifications from "../../Hooks/useDataSourceNotifications";
import useSetWorkflowPipelineCompleteEvent from "../../Hooks/useSetWorkflowPipelineCompleteEvent";
import useSetWorkflowPipelineFailEvent from "../../Hooks/useSetWorkflowPipelineFailEvent";
import useSetOcrRateLimitNotification from "../../Hooks/useSetOcrRateLimitNotification";
import useFeedExportNotification from "../../Hooks/useFeedExportNotification";
import useExportNotification from "../../Hooks/useExportNotification";
import favIconDot from "../../assets/images/favicon-dot.png";
import favIcon from "../../assets/images/favicon.png";
import { Scrollbars } from "react-custom-scrollbars";
import { sortByTimestamp } from "../../common/helpers/util";
import { format } from "date-fns";
import { resultStateEnums } from "../../common/failureEnums";

const dismissNotifications = `
  mutation($ids: [UUID!]!){
    dismissNotifications(ids: $ids)
  }
`;

const InnerWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: ${(props) => (props.menuState ? "200px" : "60px")};
  bottom: 0;
  height: 100vh;
  background: #fafafa;

  padding: 1rem;
  width: 350px;
  z-index: 2;
  transition: all 150ms;
  color: #333;
  box-shadow: 9px 0px 11px -9px rgb(0 0 0 / 20%);
`;

//DATASOURCE NOTIFICATION
const NotificationWrapper = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: #ececec;
  justify-content: top;
`;

const NotificationIcon = styled.div``;

const NotificationTitle = styled.div`
  margin-bottom: 1rem;
  font-size: 1rem;
  font-family: "Source Sans Pro Semibold";
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 12rem;
`;

const NotificationBody = styled.div`
  font-size: 0.8rem;
`;

const NotificationContent = styled.div`
  flex: 1;
  margin-left: 0.5rem;
`;

const NotificationClearIcon = styled.button`
  position: relative;
  background: #dfdfdf;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 0;
  &:hover {
    cursor: pointer;
  }
`;

const NotificationTimestamp = styled.div`
  font-size: 0.7rem;
`;

const ConnectionNotification = ({
  isClearing,
  notification,
  clearNotification,
}) => {
  const getContent = (notification) => {
    if (notification.payload.AlertType === 6) {
      return "Connection Creation Completed";
    } else if (notification.payload.AlertType === 5) {
      return "Connection Browse Completed";
    } else if (notification.payload.AlertType === 7) {
      if (notification.Finished) {
        return "Connection Import Started";
      } else {
        return "Connection Import Started";
      }
    } else if (notification.payload.AlertType === 26) {
      return notification?.payload?.ErrorMessage;
    } else {
      return null;
    }
  };

  const content = getContent(notification);
  let history = useHistory();

  const goToConnection = () => {
    clearNotification(notification);
    history.push(dataConnectionsSources(notification.payload.ConnectionId));
  };

  const clickClear = (e) => {
    e.stopPropagation();
    clearNotification(notification);
  };

  return (
    <NotificationWrapper onClick={!isClearing ? goToConnection : null}>
      <NotificationIcon>
        <MdVpnKey />
      </NotificationIcon>
      <NotificationContent>
        <NotificationTimestamp>
          {format(new Date(notification.timestamp), "MM-dd-yyyy HH:mm:ss")}
        </NotificationTimestamp>

        <NotificationTitle>
          {notification.payload.ConnectionName}
        </NotificationTitle>
        {content && <NotificationBody>{content}</NotificationBody>}
      </NotificationContent>
      <div>
        <NotificationClearIcon
          disabled={isClearing}
          onClick={(e) => clickClear(e)}
        >
          <MdDelete />
        </NotificationClearIcon>
      </div>
    </NotificationWrapper>
  );
};

const ETLNotification = ({ isClearing, notification, clearNotification }) => {
  const getContent = (notification) => {
    const status = {
      16: "Etl Provider Browse Complete",
      17: "Etl Provider Create Complete",
      18: "Etl Provider Instance Create Complete",
      19: "Etl Provider Pipeline Browse Complete",
      20: "Etl Provider Update Complete",
    };

    return status[Number(notification.payload.AlertType)] ?? "";
  };

  const content = getContent(notification);
  // let history = useHistory();

  const goToConnection = () => {
    clearNotification(notification);
    // history.push(dataConnectionsSources(notification.payload.SourceId));
  };

  const clickClear = (e) => {
    e.stopPropagation();
    clearNotification(notification);
  };

  return (
    <NotificationWrapper onClick={!isClearing ? goToConnection : null}>
      <NotificationIcon>
        <MdGrain />
      </NotificationIcon>
      <NotificationContent>
        <NotificationTitle>
          {notification.payload.EtlProviderName}
        </NotificationTitle>
        {content && <NotificationBody>{content}</NotificationBody>}
      </NotificationContent>
      <div>
        <NotificationClearIcon
          disabled={isClearing}
          onClick={(e) => clickClear(e)}
        >
          <MdDelete />
        </NotificationClearIcon>
      </div>
    </NotificationWrapper>
  );
};

const WorkflowNotification = ({
  isClearing,
  notification,
  clearNotification,
}) => {
  const getContent = (notification) => {
    const status = {
      15: "Workflow Pipeline Succeeded",
      22: "Workflow Create Complete",
    };

    return status[Number(notification.payload.AlertType)] ?? "";
  };

  const content = getContent(notification);
  // let history = useHistory();

  const goToConnection = () => {
    clearNotification(notification);
    // history.push(dataConnectionsSources(notification.payload.SourceId));
  };

  const clickClear = (e) => {
    e.stopPropagation();
    clearNotification(notification);
  };

  return (
    <NotificationWrapper onClick={!isClearing ? goToConnection : null}>
      <NotificationIcon>
        <MdGrain />
      </NotificationIcon>
      <NotificationContent>
        <NotificationTitle>
          {notification.payload.EtlPipelineName}
        </NotificationTitle>
        {content && <NotificationBody>{content}</NotificationBody>}
      </NotificationContent>
      <div>
        <NotificationClearIcon
          disabled={isClearing}
          onClick={(e) => clickClear(e)}
        >
          <MdDelete />
        </NotificationClearIcon>
      </div>
    </NotificationWrapper>
  );
};

const WarnIcon = styled.div`
  color: ${(props) => props.theme.warn};
  font-size: 1.3rem;
  margin-right: 0.5rem;
`;

// Export Audit Notification

const AuditNotification = ({ isClearing, notification, clearNotification }) => {
  const getContent = (notification) => {
    if (notification.payload.AlertType === 50) {
      return (
        <>
          <NotificationTitle>
            Export {notification?.payload?.ExportType}
          </NotificationTitle>

          <>
            <div>
              <a
                href={notification?.payload?.FileUrl ?? ""}
                rel="noopener noreferrer"
                target="_blank"
              >
                Download {notification?.payload?.ExportType}
              </a>
            </div>
          </>

          {notification?.payload?.ErrorMessage && (
            <div style={{ display: "flex", alignItems: "top" }}>
              <MdError style={{ color: "red", fontSize: "1.3rem" }} />{" "}
              {notification?.payload?.ErrorMessage}
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const content = getContent(notification);

  const clickClear = (e) => {
    e.stopPropagation();
    clearNotification(notification);
  };
  return (
    <NotificationWrapper onClick={null} style={{ cursor: "default" }}>
      <NotificationIcon>
        <FaDatabase />
      </NotificationIcon>
      <NotificationContent>
        <NotificationTimestamp>
          {format(new Date(notification.timestamp), "MM-dd-yyyy HH:mm:ss")}
        </NotificationTimestamp>
        <NotificationTitle>
          {notification.payload.DataSourceName}
        </NotificationTitle>
        {content && <NotificationBody>{content}</NotificationBody>}
      </NotificationContent>
      <div>
        <NotificationClearIcon
          disabled={isClearing}
          onClick={(e) => clickClear(e)}
        >
          <MdDelete />
        </NotificationClearIcon>
      </div>
    </NotificationWrapper>
  );
};

const FeedNotification = ({ isClearing, notification, clearNotification }) => {
  const getContent = (notification) => {
    if (notification.payload.AlertType === 33) {
      return (
        <>
          <NotificationTitle>
            {notification?.payload?.FeedName}
          </NotificationTitle>

          <>
            {notification?.payload?.FileUrls.map((files) => {
              return (
                <div>
                  <a
                    href={files?.Url ?? ""}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {files?.Name}
                  </a>
                </div>
              );
            })}
          </>

          {notification?.payload?.ErrorMessage && (
            <div style={{ display: "flex", alignItems: "top" }}>
              <MdError style={{ color: "red", fontSize: "1.3rem" }} />{" "}
              {notification?.payload?.ErrorMessage}
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const content = getContent(notification);
  let history = useHistory();
  const goToNotification = () => {
    clearNotification(notification);
    history.push(dataSource(notification.payload.SourceId));
  };

  const clickClear = (e) => {
    e.stopPropagation();
    clearNotification(notification);
  };
  return (
    <NotificationWrapper
      onClick={
        notification.payload.AlertType !== 27 &&
        notification.payload.AlertType !== 33
          ? goToNotification
          : null
      }
    >
      <NotificationIcon>
        <FaDatabase />
      </NotificationIcon>
      <NotificationContent>
        <NotificationTimestamp>
          {format(new Date(notification.timestamp), "MM-dd-yyyy HH:mm:ss")}
        </NotificationTimestamp>
        <NotificationTitle>
          {notification.payload.DataSourceName}
        </NotificationTitle>
        {content && <NotificationBody>{content}</NotificationBody>}
      </NotificationContent>
      <div>
        <NotificationClearIcon
          disabled={isClearing}
          onClick={(e) => clickClear(e)}
        >
          <MdDelete />
        </NotificationClearIcon>
      </div>
    </NotificationWrapper>
  );
};

const DataSourceNotification = ({
  isClearing,
  notification,
  clearNotification,
}) => {
  const getContent = (notification) => {
    if (notification.payload.AlertType === 11) {
      //DataSourceRefreshStateChange
      const status = [
        "Refresh Done",
        "Refresh Queued",
        "Reading Data",
        "Running Rules",
        "Processing Results",
        "Retrieving Remote File",
        "Rule Mapping Error",
      ];
      const stateName = resultStateEnums.find(
        (rse) => rse?.value === notification?.payload?.ResultState
      )?.name;
      return (
        <>
          {stateName ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <WarnIcon>
                <MdWarning />
              </WarnIcon>{" "}
              {stateName}
            </div>
          ) : (
            status[Number(notification.payload.Status) - 1]
          )}
        </>
      );
    } else if (notification.payload.AlertType === 21) {
      return "New Data Source Found";
    } else if (notification.payload.AlertType === 27 || notification.payload.AlertType === 50) {
      let shouldOpenInSameTab = false;
      if (
        notification?.payload?.FileUrl &&
        notification?.payload?.FileUrl != null &&
        notification?.payload?.FileUrl != ""
      ) {
        // Parse the URL using the URL API
        const url = new URL(notification?.payload?.FileUrl ?? "");
        // Use URLSearchParams to easily access the query parameters
        const searchParams = new URLSearchParams(url.search);
        // Check if 'userAction' is 'download'
        shouldOpenInSameTab = searchParams.get("userAction") === "download";
      
      return (
        <>
          <a
            href={notification?.payload?.FileUrl ?? ""}
            target={shouldOpenInSameTab ? "_self" : "_blank"}
            rel={"noopener noreferrer"}
          >
            CSV Download Available
          </a>
        </>
          );
      }
    } else if (notification.payload.AlertType === 20) {
      const { RuleInstanceId, RuleInstanceVersionId, DataSourceId } =
        notification.payload;

      return (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <WarnIcon>
              <MdWarning />
            </WarnIcon>{" "}
            <a
              href={`/sources/${DataSourceId}/policies/${RuleInstanceId}/${RuleInstanceVersionId}/update`}
            >
              Check Rule Mapping
            </a>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const content = getContent(notification);
  let history = useHistory();
  const goToNotification = () => {
    if (notification?.payload?.AlertType === 50) {
      clearNotification(notification);
    } else {
      clearNotification(notification);
      history.push(dataSource(notification.payload.SourceId));
    }
  };

  const clickClear = (e) => {
    e.stopPropagation();
    clearNotification(notification);
  };
  return (
    <NotificationWrapper
      onClick={
        notification.payload.AlertType !== 27 &&
        notification.payload.AlertType !== 33
          ? goToNotification
          : null
      }
    >
      <NotificationIcon>
        <FaDatabase />
      </NotificationIcon>
      <NotificationContent>
        <NotificationTimestamp>
          {format(new Date(notification.timestamp), "MM-dd-yyyy HH:mm:ss")}
        </NotificationTimestamp>
        <NotificationTitle>
          {notification.payload.DataSourceName}
        </NotificationTitle>
        {content && <NotificationBody>{content}</NotificationBody>}
      </NotificationContent>
      <div>
        <NotificationClearIcon
          disabled={isClearing}
          onClick={(e) => clickClear(e)}
        >
          <MdDelete />
        </NotificationClearIcon>
      </div>
    </NotificationWrapper>
  );
};

const GeneralNotificationHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const CloseNotifications = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const GeneralNotificationTitle = styled.div`
  font-size: 1.2rem;
  color: ${(props) => props.theme.onSecondarySurface};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ClearNotifications = styled.div`
  margin-top: auto;
  background-color: ${(props) => props.theme.secondarySurface};
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
`;

const query = `
query{
  unreadNotifications {
        totalCount
        nodes {
           payload
           id
           selfDismissing
           timestamp
        }
  }
}
`;

const QuickButton = styled.div`
  display: flex;
  align-items: center;
  background: transparent;
  color: ${(props) => props.theme.menuText};
  padding: 1rem;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  margin-right: 0;
  margin-left: 0;
  border-left: 4px solid transparent;
  font-size: 1em;
  text-decoration: none;
  &:hover {
    cursor: ${(props) => (props.hasNotifications ? "pointer" : "default")};
    background: ${(props) => props.theme.menuHoverActive};
  }
  &.active {
    background: ${(props) => props.theme.menuHoverActive};
    border-left: 4px solid ${(props) => props.theme.onSecondarySurface};
  }
`;

const MenuIcon = styled.div`
  font-size: 1.2em;
  margin-right: ${(props) => (props.menuState ? ".5rem" : "")};
  opacity: ${(props) => (props.hasNotifications ? 1 : 0.5)};
  padding-top: 0.1rem;
  color: ${(props) =>
    props.hasNotifications
      ? props.theme.onSecondarySurface
      : props.theme.menuText};
`;

const GeneralNotifications = ({ open, setOpen, menuState }) => {
  const [notifications, setNotifications] = useState([]);

  const { lastDataSourceNotification } = useDataSourceNotifications();
  const { feedExportNotification } = useFeedExportNotification();
  const { exportNotification } = useExportNotification();
  const { etlProviderCreateCompleted } = useETLProviderCreateNotification();
  const { etlProviderInstanceCreateCompleted } =
    useETLProviderInstanceCreateNotification();

  const { etlPipelineAddCompleteEvent } = useEtlPipelineAddCompleteEvent();
  const { etlProviderUpdateCompleted } = useEtlProviderUpdateCompleted();
  const { workflowPipelineCompleteEvent } =
    useSetWorkflowPipelineCompleteEvent();

  const { workflowPipelineFailEvent } = useSetWorkflowPipelineFailEvent();
  const { ocrRateLimitNotification } = useSetOcrRateLimitNotification();
  //GET UNDREAD NOTIFICATIONS
  const [{ data }, doFetch] = useApi();

  // handle clicks outside

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, open);

  useEffect(() => {
    doFetch({ query: query });
  }, [doFetch]);
  //TODO finish notifications
  useEffect(() => {
    if (data) {
      const unread = data?.unreadNotifications?.nodes ?? [];
      const parsedUnread = unread.map((notif) => {
        return {
          ...notif,
          payload: notif?.payload ? JSON.parse(notif?.payload) : {},
        };
      });

      const cleanParsed = parsedUnread.filter((pu) => !pu.payload) ?? [];

      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [...cleanParsed],
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (
      lastDataSourceNotification &&
      lastDataSourceNotification?.payload?.AlertType === 27 &&
      lastDataSourceNotification?.payload?.FileUrl
    ) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [lastDataSourceNotification],
        })
      );
    } else if (
      lastDataSourceNotification &&
      lastDataSourceNotification?.payload?.AlertType !== 27 &&
      lastDataSourceNotification?.payload?.AlertType !== 23
    ) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [lastDataSourceNotification],
        })
      );
    }
  }, [lastDataSourceNotification]);

  useEffect(() => {
    if (etlProviderCreateCompleted) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [etlProviderCreateCompleted],
        })
      );
    }
  }, [etlProviderCreateCompleted]);

  useEffect(() => {
    if (etlProviderCreateCompleted) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [etlProviderCreateCompleted],
        })
      );
    }
  }, [etlProviderCreateCompleted]);

  useEffect(() => {
    if (etlProviderInstanceCreateCompleted) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [etlProviderInstanceCreateCompleted],
        })
      );
    }
  }, [etlProviderInstanceCreateCompleted]);

  useEffect(() => {
    if (etlPipelineAddCompleteEvent) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [etlPipelineAddCompleteEvent],
        })
      );
    }
  }, [etlPipelineAddCompleteEvent]);

  useEffect(() => {
    if (etlProviderUpdateCompleted) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [etlProviderUpdateCompleted],
        })
      );
    }
  }, [etlProviderUpdateCompleted]);

  useEffect(() => {
    if (workflowPipelineCompleteEvent) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [workflowPipelineCompleteEvent],
        })
      );
    }
  }, [workflowPipelineCompleteEvent]);

  useEffect(() => {
    if (workflowPipelineFailEvent) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [workflowPipelineFailEvent],
        })
      );
    }
  }, [workflowPipelineFailEvent]);

  useEffect(() => {
    if (ocrRateLimitNotification) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [ocrRateLimitNotification],
        })
      );
    }
  }, [ocrRateLimitNotification]);

  useEffect(() => {
    if (feedExportNotification) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [feedExportNotification],
        })
      );
    }
  }, [feedExportNotification]);

  useEffect(() => {
    if (exportNotification) {
      setNotifications((prevNotifications) =>
        update(prevNotifications, {
          $push: [exportNotification],
        })
      );
    }
  }, [exportNotification]);

  useEffect(() => {
    if (notifications && notifications.length) {
      document.getElementById("favicon").href = favIconDot;

      const newTitle = "(" + notifications.length + ") BaseCap Analytics";
      document.title = newTitle;
    } else {
      document.getElementById("favicon").href = favIcon;
      document.title = "BaseCap Analytics";
    }
  }, [notifications]);

  const hasNotifications = notifications && notifications.length;

  //Clearing Notifications

  const [{ loading: isClearing }, clear] = useApi(); // eslint-disable-line no-empty-pattern

  const clearAll = () => {
    clear({
      query: dismissNotifications,
      variables: {
        ids: notifications.map((n) => n.id),
      },
    });

    setNotifications([]);
    setOpen(false);
  };

  const clearSourceNotification = (notification) => {
    //NewChatMessage
    const filterNotifications = notifications.filter(
      (n) => n.id !== notification.id
    );

    clear({
      query: dismissNotifications,
      variables: {
        ids: [notification?.id],
      },
    });

    setNotifications(filterNotifications);
    if (!filterNotifications.length) setOpen(false);
  };

  return (
    <>
      <>
        <QuickButton
          hasNotifications={hasNotifications}
          onClick={() => (hasNotifications ? setOpen(!open) : null)}
          title="Notifications"
        >
          <MenuIcon hasNotifications={hasNotifications} menuState={menuState}>
            {notifications.length ? (
              <MdNotificationsActive />
            ) : (
              <MdNotifications />
            )}
          </MenuIcon>
          {menuState && "Notifications"}
        </QuickButton>
      </>

      {open && (
        <InnerWrapper ref={wrapperRef} menuState={menuState}>
          <GeneralNotificationHeader>
            <GeneralNotificationTitle>
              Notification Center
            </GeneralNotificationTitle>
            <CloseNotifications onClick={() => setOpen(!open)} title="Close">
              <MdClose />
            </CloseNotifications>
          </GeneralNotificationHeader>

          <Scrollbars style={{ minHeight: "calc(80vh)" }}>
            {sortByTimestamp(notifications).map((notification, i) => {
              const payload = notification?.payload;
              if (payload?.SourceType === 1)
                return (
                  <DataSourceNotification
                    notification={notification}
                    key={`dataSourceNotification-${i}`}
                    isClearing={isClearing}
                    clearNotification={clearSourceNotification}
                  />
                );
              if (payload?.SourceType === 7)
                return (
                  <FeedNotification
                    notification={notification}
                    key={`dataSourceNotification-${i}`}
                    isClearing={isClearing}
                    clearNotification={clearSourceNotification}
                  />
                );
              if (payload?.SourceType === 5)
                return (
                  <ConnectionNotification
                    notification={notification}
                    key={`connectionNotification-${i}`}
                    isClearing={isClearing}
                    clearNotification={clearSourceNotification}
                  />
                );

              if (payload?.SourceType === 3 || payload?.SourceType === 4)
                return (
                  <ETLNotification
                    notification={notification}
                    key={`etlNotification-${i}`}
                    isClearing={isClearing}
                    clearNotification={clearSourceNotification}
                  />
                );

              if (payload?.SourceType === 5)
                return (
                  <WorkflowNotification
                    notification={notification}
                    key={`Workflow-Notification-${i}`}
                    isClearing={isClearing}
                    clearNotification={clearSourceNotification}
                  />
                );
              if (payload?.SourceType === 12)
                return (
                  <AuditNotification
                    notification={notification}
                    key={`auditNotification-${i}`}
                    isClearing={isClearing}
                    clearNotification={clearSourceNotification}
                  />
                );
              return null;
            })}
          </Scrollbars>
          {notifications && notifications.length ? (
            <ClearNotifications onClick={clearAll} title="Clear All">
              Clear All
            </ClearNotifications>
          ) : null}
        </InnerWrapper>
      )}
    </>
  );
};

const NotificationBar = ({ menuState }) => {
  const [open, setOpen] = useState(null);
  const { user } = useContext(AuthContext);
  // const [granted, setGranted] = useState(false);
  // const [supported, setSupported] = useState(false);
  if (!user) return null;

  // useEffect(() => {
  //   const PERMISSION_GRANTED = "granted";
  //   const PERMISSION_DENIED = "denied";

  //   if ("Notification" in window && window.Notification) {
  //     setSupported(true);
  //     if (window.Notification.permission === PERMISSION_GRANTED) {
  //       setGranted(true);
  //     }
  //   }

  //   window.Notification.requestPermission(permission => {
  //     let result = permission === PERMISSION_GRANTED;
  //     setGranted(result);
  //   });
  // }, []);

  // Disabled for now Play sound on notification
  // const onShowNotification = () => {
  //   document.getElementById("sound").play();
  // };

  return (
    <GeneralNotifications open={open} setOpen={setOpen} menuState={menuState} />
  );
};

export default NotificationBar;
