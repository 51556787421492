import React, { useEffect, useState, useCallback } from "react";
import DataSourceBusinessLogicFailuresWidget from "../../../components/Widgets/DataSourceWidgets/DataSourceBusinessLogicFailuresWidget";
import DataSourceColumnFailuresWidget from "../../../components/Widgets/DataSourceWidgets/DataSourceColumnFailuresWidget";
import SplashLoader from "../../../components/Loaders/SplashLoader";
import CTAMessage from "../../../components/Help/CTAMessage";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import styled from "styled-components/macro";
import DataSourceFailureDetails from "../../../components/Widgets/DataSourceWidgets/DataSourceFailureDetails";
import DataSourceRuleInstanceHistory from "../../../components/Widgets/DataSourceWidgets/DataSourceRuleInstanceHistory";
import DataSourceFailuresHistory from "../../../components/Widgets/DataSourceWidgets/DataSourceFailuresHistory";
import DataQualitySpotlight from "../../../components/Widgets/DataSourceWidgets/DataQualitySpotlight";
import ReportScoreOverview from "./ReportScoreOverview";
import SmallToggle from "../../../components/Toggle/SmallToggle";
import useDataSourceNotifications from "../../../Hooks/useDataSourceNotifications";
import { dataSource } from "../../../common/paths";
import { useHistory } from "react-router-dom";
import { resultStateEnums } from "../../../common/failureEnums";

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-auto-rows: dense;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  position: relative;
`;

const DashboardGridTop = styled.div`
  display: flex;
  margin-bottom: 1rem;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const SpotlightContainer = styled.div`
  width: 400px;
  margin-right: 1rem;
  @media (max-width: 1080px) {
    width: auto;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const ReportStatusContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10;
`;

const ReportStatus = styled.div`
  background: rgb(0, 153, 255);
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #efefef;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
`;

const Report = ({
  loading,
  errors,
  sourceId,
  reportData,
  reportStatus,
  dataSourceName,
  errorsLatest,
  reportId,
}) => {
  const { lastDataSourceNotification, setLastDataSourceNotification } =
    useDataSourceNotifications();
  const [ruleInstanceDisplay, setRuleInstanceDisplay] = useState({
    bar: null,
    type: "Historical",
  });
  const [view, setView] = useState("Historical");
  const [spotlightView, setSpotlightView] = useState("Policy");
  const [progressBar, setProgressBar] = useState({
    showProgress: reportStatus === "NONE",
    inProgress: reportStatus === "NONE",
    failed: "",
  });

  useEffect(() => {
    const payload = lastDataSourceNotification?.payload;
    if (
      payload?.AlertType === 11 &&
      payload?.SourceId === sourceId &&
      payload?.Status === 1 &&
      payload?.ResultState === 1
    ) {
      setTimeout(() => {
        setProgressBar({
          showProgress: true,
          inProgress: false,
          failed: "",
        });
      }, 10000); //Delay for db update
    } else if (
      payload?.AlertType === 11 &&
      payload?.SourceId === sourceId &&
      payload?.Status === 1 &&
      payload?.ResultState !== 1
    ) {
      setTimeout(() => {
        setProgressBar({
          showProgress: true,
          inProgress: false,
          failed:
            resultStateEnums.find(
              (rse) =>
                rse?.value === lastDataSourceNotification?.payload?.ResultState
            )?.name ?? "Failed",
        });
      }, 10000); //Delay for db update
    }
  }, [sourceId, lastDataSourceNotification]);

  const setRule = useCallback(
    (data) => {
      const barToSet = data?.bar;

      setRuleInstanceDisplay((prevData) => {
        if (!barToSet) return { bar: null };
        return data;
      });
    },
    [setRuleInstanceDisplay]
  );

  const toggleData = [
    {
      name: "Historical",
      action: () => setView("Historical"),
    },
    {
      name: "Details",
      action: () => setView("Details"),
    },
  ];
  let history = useHistory();
  const goToDataSource = () => {
    setLastDataSourceNotification(null);
    setProgressBar({
      showProgress: false,
      inProgress: false,
      failed: "",
    });

    history.push(dataSource(sourceId));
  };

  //Show loading of report
  if (loading) return <SplashLoader text="Loading Report" />;

  const failureCount = reportData?.totalFailures ?? null;
  const failureData =
    reportData?.ruleFailures?.failures
      .filter((f) => f.failureCount)
      .sort((a, b) => b.failureCount - a.failureCount)
      .slice(0, 10) ?? [];

  const failureHistory = reportData?.failuresHistory ?? null;
  const failureDetails = reportData?.failureDetails ?? null;
  const columnFailures =
    reportData?.failureDetails?.columnEntries
      .filter((c) => c.failureCount)
      .sort((a, b) => b.failureCount - a.failureCount)
      .slice(0, 10) ?? [];

  if (errors)
    return (
      <CTAMessage
        msg={
          <div>
            <ErrorMessages errors={errors} />
          </div>
        }
      />
    );

  if (errorsLatest)
    return (
      <CTAMessage
        msg={
          <div>
            <ErrorMessages errors={errorsLatest} />
          </div>
        }
      />
    );

  return (
    <>
      <ReportScoreOverview
        apiData={reportData}
        sourceId={sourceId}
        loading={loading}
      />

      {/* WIDGETS Upto 4 */}
      <DashboardGridTop>
        <SpotlightContainer>
          <DataQualitySpotlight
            sourceId={sourceId}
            loading={loading}
            data={failureDetails}
            spotlightView={spotlightView}
            setSpotlightView={setSpotlightView}
          />
        </SpotlightContainer>
        <div style={{ flex: 1 }}>
          {spotlightView === "Policy" ? (
            <DataSourceBusinessLogicFailuresWidget
              sourceId={sourceId}
              loading={loading}
              data={failureData}
              dataSourceName={dataSourceName}
              setRuleInstanceDisplay={setRule}
            />
          ) : (
            <DataSourceColumnFailuresWidget
              sourceId={sourceId}
              loading={loading}
              data={columnFailures}
              dataSourceName={dataSourceName}
            />
          )}
        </div>
      </DashboardGridTop>

      <DashboardGrid>
        {ruleInstanceDisplay?.bar && (
          <div
            style={{
              position: "absolute",
              right: 0,
              zIndex: 2,
              padding: "1rem",
            }}
          >
            <SmallToggle toggleState={view} toggleData={toggleData} />
          </div>
        )}

        {ruleInstanceDisplay?.bar && view === "Details" && (
          <DataSourceFailureDetails
            sourceId={sourceId}
            loading={loading}
            data={ruleInstanceDisplay}
            failureCount={failureCount}
          />
        )}

        {ruleInstanceDisplay?.bar && view === "Historical" && (
          <DataSourceRuleInstanceHistory
            sourceId={sourceId}
            reportId={reportId}
            loading={loading}
            barData={ruleInstanceDisplay}
          />
        )}

        {ruleInstanceDisplay?.bar ? null : (
          <DataSourceFailuresHistory
            sourceId={sourceId}
            loading={loading}
            data={failureHistory}
          />
        )}
      </DashboardGrid>
      {progressBar.showProgress ? (
        <ReportStatusContainer>
          <ReportStatus>
            {progressBar.inProgress ? (
              "The Report is currently updating..."
            ) : progressBar.failed ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                {progressBar.failed}
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                The Report has finished updating.{" "}
                <div
                  style={{
                    color: "#fefefe",
                    fontWeight: "bold",
                    cursor: "pointer",
                    marginLeft: ".5rem",
                  }}
                  onClick={() => goToDataSource()}
                  data-testid="refreshLink"
                >
                  Refresh
                </div>
              </div>
            )}
          </ReportStatus>
        </ReportStatusContainer>
      ) : null}
    </>
  );
};

export default Report;
